import { FC, ReactNode } from 'react';
import { Box, IconButton, SvgIcon, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ThemeSettings from 'src/components/ThemeSettings';

import Sidebar from './Sidebar';
import Header from './Header';
import Footer from 'src/components/Footer';
import useSidebar from 'src/hooks/useSidebar';
import useAuth from '../../hooks/useAuth';

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = () => {
  const theme = useTheme();

  const sidebarOpen = useSidebar(selector => selector.sidebarToggle);
  const shouldChangePassword = useAuth(selector => selector.user.shouldChangePassword);

    return (
    <Box>
        {!shouldChangePassword && (
            <>
                <Header />
                <Sidebar />
            </>
        )}
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          pl: sidebarOpen && !shouldChangePassword ? `${theme.sidebar.width}` : undefined,
          transition: '0.5s cubic-bezier(.36,-0.01,0,.77)',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>

            <Outlet />
            <Footer />
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </Box>
  );
};

export default AccentSidebarLayout;
