import React, { FC, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import { User as OidcUser } from 'oidc-client';
import { User } from '../models/user';
import AuthService from 'src/services/auth/authService';
import { AuthResource } from 'src/models/authResource';
import identityService from 'src/services/http/identityService';
import { createContext } from 'use-context-selector';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';




interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
    authClaims: AuthResource[];
}

export interface AuthContextValue extends AuthState {
    method: 'OIDC';
    login: (email: string, tenant: number) => Promise<void>;
    logout: () => Promise<void>;
    signinRedirectCallback: () => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: 'INITIALIZE';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
        authClaims: AuthResource[];
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        user: User;
        authClaims: AuthResource[];
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    authClaims: []
};

const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
                authClaims: action.payload.authClaims || []
            };
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                authClaims: action.payload.authClaims || []
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
};

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'OIDC',
    login: (email: string, tenant: number) => Promise.resolve(),
    logout: () => Promise.resolve(),
    signinRedirectCallback: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {

    console.log('AuthProvider - Component');

    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const { enqueueSnackbar } = useSnackbar();

    const navigator = useNavigate();


    useEffect(() => {
        const initialize = async (): Promise<void> => {

            const oidcUser: OidcUser | null = await AuthService.getUser();

            const user: User = {
                avatar: oidcUser?.profile?.picture || '',
                email: oidcUser?.profile?.email || '',
                name: oidcUser?.profile?.name || '',
                coverImg: oidcUser?.profile?.coverImg || '',
                id: Number(oidcUser?.profile?.sub) || 0,
                description: oidcUser?.profile?.description || '',
                jobtitle: oidcUser?.profile?.jobtitle || '',
                username: oidcUser?.profile?.username || '',
                surname: oidcUser?.profile?.family_name || '',
                tenantId: Number(oidcUser?.profile?.tenant_id) || 0,
                shouldChangePassword: oidcUser?.profile?.should_change_password === "True" || oidcUser?.profile?.should_change_password === "true",
                allowedTenantIds: JSON.parse(oidcUser?.profile?.allowed_tenants ? oidcUser?.profile?.allowed_tenants : "[]") || []
            };

            const isAuthenticated = oidcUser?.expired === false || false;

            if (isAuthenticated) {

                let authClaimsResult = await authClaims();

                if (!authClaimsResult) {
                    enqueueSnackbar('Oturumunuz sonlandırıldı. Lütfen tekrar giriş yapınız', { variant: 'error' });
                    logout();
                    return;
                }

                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated,
                        user: user,
                        authClaims: authClaimsResult || []
                    },
                });
            }
            else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated,
                        user: null,
                        authClaims: []
                    },
                });
            }

            if ((isAuthenticated) && user.shouldChangePassword) {
                navigator('change-password', { replace: true })
            }

        };

        console.log('AuthProvider - useEffect');


        initialize();
    }, []);


    const authClaims = async (): Promise<AuthResource[]> => {

        let result = await identityService.getResources();

        let authClaims: AuthResource[] = [];

        if (result.success) {
            authClaims =
                result.data.session?.authorizationClaims?.authResources
                    .filter((x) => x.status === 1)
                    .map(x => (
                        {
                            resourceCode: x.resourceCode,
                            resourceName: x.resourceName,
                            parentResourceCode: x.parentResourceCode,
                            actionTypes: x.authActions
                                .filter((y) => y.status === 1)
                                .map(y => y.actionType)
                        } as AuthResource
                    )
                    )
        }

        return authClaims;

    }


    const login = useCallback((email: string, tenant: number): Promise<void> => AuthService.login(email, tenant), []);

    const logout = useCallback((): Promise<void> => AuthService.logout(), []);

    const signinRedirectCallback = useCallback(async (): Promise<void> => {
        const oidcUser = await AuthService.signinRedirectCallback();

        const user: User = {
            avatar: oidcUser?.profile?.picture || '',
            email: oidcUser?.profile?.email || '',
            name: oidcUser?.profile?.name || '',
            coverImg: oidcUser?.profile?.coverImg || '',
            id: Number(oidcUser?.profile?.sub) || 0,
            description: oidcUser?.profile?.description || '',
            jobtitle: oidcUser?.profile?.jobtitle || '',
            username: oidcUser?.profile?.username || '',
            surname: oidcUser?.profile?.family_name || '',
            tenantId: Number(oidcUser?.profile?.tenant_id) || 0,
            shouldChangePassword: oidcUser?.profile?.should_change_password === "True" || oidcUser?.profile?.should_change_password === "true",
            allowedTenantIds: JSON.parse(oidcUser?.profile?.allowed_tenants ? oidcUser?.profile?.allowed_tenants : "[]") || []
        };

        dispatch({
            type: 'LOGIN',
            payload: {
                user: user,
                authClaims: await authClaims() || []
            },
        });

    }, [dispatch]);

    const contextValue: AuthContextValue = useMemo(() => ({
        isAuthenticated: state.isAuthenticated,
        isInitialized: state.isInitialized,
        user: state.user,
        authClaims: state.authClaims,
        method: 'OIDC',
        login,
        logout,
        signinRedirectCallback
    }), [state, login, logout, signinRedirectCallback]);


    return (
        <AuthContext.Provider
            value={contextValue}
        >
            {children}
        </AuthContext.Provider>
    );
};


export default AuthContext;
