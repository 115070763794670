import { AddCard, Assessment, Report } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import useAuth from 'src/hooks/useAuth';
import { MenuItem, MenuItems } from 'src/layouts/AccentSidebarLayout/Sidebar/SidebarMenu/items';
import { ViewTypes } from 'src/models/viewTypes';
import adminService from 'src/services/http/adminService';
import processesHttpService from 'src/services/http/processesHttpService';
import reportService from 'src/services/http/reportService';
import cacheService from 'src/services/storage/cacheService';
import { createContext } from 'use-context-selector';

export interface SidebarContextType {
  sidebarToggle: boolean;
  menuItems: MenuItems[];
  favourites: MenuItem[];
  addFavourite: (menuItem: MenuItem) => void;
  removeFavourite: (link: string) => void;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType
);

export const SidebarProvider: FC = ({ children }) => {

  const isMobile = useMediaQuery('(max-width: 768px)' , {noSsr: true});

  const [sidebarToggle, setSidebarToggle] = useState<boolean>(isMobile ? false : true);

  const [menuItems, setMenuItems] = useState<MenuItems[]>([]);

  const [favourites, setFavourites] = useState<MenuItem[]>(cacheService.get<MenuItem[]>('favourites') || []);

  const isAuthenticated = useAuth(selector => selector.isAuthenticated);

  const isInitialized = useAuth(selector => selector.isInitialized);

  const [menuItemsArranged, setMenuItemsArranged] = useState<boolean>(false);

  const toggleSidebar = useCallback(() => setSidebarToggle(prevState => !prevState), [])

  const closeSidebar = useCallback(() => setSidebarToggle(false), []);

  const addFavourite = useCallback((menuItem: MenuItem) => {
    setFavourites(prevFavourites => {
      const newMenuItem: MenuItem = {
        ...menuItem,
        items: undefined
      }
      cacheService.set('favourites', [...prevFavourites, newMenuItem]);

      return [...prevFavourites, newMenuItem];
    });
  }, [])

  const removeFavourite = useCallback((link: string) => {
    setFavourites(prevFavourites => {
      const filteredFavourites = prevFavourites.filter(f => f.link !== link);
      cacheService.set('favourites', filteredFavourites);
      return filteredFavourites;
    });
  }, [])

  console.log('SidebarProvider - Component');

  useEffect(() => {
    setSidebarToggle(isMobile ? false : true)
  }, [isMobile]);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    console.log('SidebarProvider - useEffect');

    if (isInitialized && isAuthenticated && !menuItemsArranged) {
      console.log('SidebarProvider - useEffect -- arrange menu items');
      arrangeMenuItems();
    }

  }, [isInitialized, isAuthenticated, menuItemsArranged]);

  const arrangeMenuItems = async () => {
    try {

      const newMenuItems: MenuItems[] = [
        {
          heading: "Rapor",
          items: []
        },
        {
          heading: "Göçtur Main",
          items: []
        }
      ]


      const [reportMenuItems, resourcesMenuItems, processMenuItems] = await Promise.all([
        reportService.getReportsAsMenuItem(),
        adminService.getResourcesAsMenuItems(),
        processesHttpService.getProcessesAsMenuItem()
      ]);


      if (!reportMenuItems.success) {
        enqueueSnackbar('Raporlar çekilemedi', { variant: 'error' });
      }
      else {
        newMenuItems[0].items = [
          {
            name: 'Raporlar',
            icon: Assessment,
            items: reportMenuItems.data.map(r => ({
              name: r.reportName,
              link: `/reports/${r.id}/${r.reportType}`,
              badgeTooltip: r.tooltip
            }))
          },
          {
            name: 'Rapor Oluştur',
            link: '/reports',
            resourceCode: 'ReportInformation_Res',
            icon: AddCard,
            badgeTooltip: 'Rapor Oluştur',
          }
        ]
      }


      if (processMenuItems.isFailure) {
        enqueueSnackbar(processMenuItems.error?.message, { variant: 'error' });
      }
      else {
        newMenuItems[1].items =
          resourcesMenuItems.filter(r => r.resourceType === 8)
            .map(r => ({
              name: r.resourceName,
              resourceCode: r.resourceCode,
              items: resourcesMenuItems
                .filter(x => x.parentResourceCode === r.resourceCode)
                .map(x => ({
                  name: x.resourceName,
                  resourceCode: x.resourceCode,
                  items: processMenuItems.data.filter(p => p.resourceCode === x.resourceCode && p.viewType !== ViewTypes.DetailForm)
                    .map(p => ({
                      name: p.name,
                      // link: `/processes/${p.path}`,
                      link: p.viewType === ViewTypes.Embed ? `/embed/${p.path}` : `/processes/${p.path}`,
                    }))
                }))

            }))
      }

      setMenuItems(prev => {
        setMenuItemsArranged(true);
        return [...newMenuItems, ...menuItems]
      })


    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setMenuItemsArranged(false);
    }


  }

  const contextValue = useMemo(() => ({
    favourites, addFavourite, removeFavourite, menuItems, sidebarToggle, toggleSidebar, closeSidebar
  }), [favourites, addFavourite, removeFavourite, menuItems, sidebarToggle, toggleSidebar, closeSidebar]);


  return (
    <SidebarContext.Provider
      value={contextValue}
    >
      {children}
    </SidebarContext.Provider>
  );
};
