import { Tenant } from "src/models/tenant";
import BaseHttpService from "../http/baseHttpService";
import ApiConstants from "src/constants/apiConstants";
import { ResponseWrapper } from "src/models/responseWrapper";


class TenantService {

    private _httpService: BaseHttpService;

    constructor() {
        this._httpService = new BaseHttpService(ApiConstants.AdminApi);
    }

    async getUserAllowedTenants(email: string): Promise<Tenant[]> {
        var response = await this._httpService.get<ResponseWrapper<Tenant[]>>('/user/getUserTenants?email=' + email);

        if (!response.data.success) {
            throw new Error(response.data.message);
        }

        return response.data.data;

    }

}

export default new TenantService;