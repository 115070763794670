const enJSON = {
    'previous': 'Previous',
    'next': 'Next',
    'submit' : 'Submit',
    'copyright' : 'All rights reserved.',
    'file_upload_button_tooltip' : 'Upload a file',
    'uploadedFiles' : 'Uploaded files',
    'plsFillFieldsAlert' : 'Please fill all the fields marked with *',
    'yes' : 'Yes',
    'no' : 'No',
    'other' : 'Other',
    'general' : 'General',
    'question' : 'Question',
    'detail' : 'Detail',
    'Main Option': 'Main Option',
    'Sub Option': 'Sub Option',
    'Electronics & HVAC Repairs': 'Electronics & HVAC Repairs',
    'Food and Beverage': 'Food and Beverage',
    'Room and Bathroom Amenities': 'Room and Bathroom Amenities',
    'Bathroom & Plumbing Repairs': 'Bathroom & Plumbing Repairs',
    'Information Services': 'Information Services',
    'General Services': 'General Services',
    'General Repairs & Maintenance': 'General Repairs & Maintenance',
    'Air Conditioning Issue': 'Air Conditioning Issue',
    'Heating System Malfunction': 'Heating System Malfunction',
    'Wi-Fi Connectivity Problem': 'Wi-Fi Connectivity Problem',
    'Minibar Malfunction': 'Minibar Malfunction',
    'In-Room Phone Line Issue': 'In-Room Phone Line Issue',
    'Electrical Outlet Issue': 'Electrical Outlet Issue',
    'Burnt-out Bulb': 'Burnt-out Bulb',
    'Light Switch Issue': 'Light Switch Issue',
    'Smoke Detector Malfunction': 'Smoke Detector Malfunction',
    'Safe Box Electricity': 'Safe Box Electricity',
    'Room Service Order': 'Room Service Order',
    'Special Dietary Request': 'Special Dietary Request',
    'Breakfast Services and Timing': 'Breakfast Services and Timing',
    'Allergen Information Inquiry': 'Allergen Information Inquiry',
    'Coffee and Tea Provisions': 'Coffee and Tea Provisions',
    'Late-Night Snack Options': 'Late-Night Snack Options',
    'A la Carte Reservation Assistance': 'A la Carte Reservation Assistance',
    'Mini-Fridge Refill': 'Mini-Fridge Refill',
    'Bedding and Linens Request': 'Bedding and Linens Request',
    'Extra Pillow Request': 'Extra Pillow Request',
    'Prayer Mat Request': 'Prayer Mat Request',
    'Family & Childcare Items Request': 'Family & Childcare Items Request',
    'Crib Request': 'Crib Request',
    'Extra Hanger Request': 'Extra Hanger Request',
    'Extra Bed Request': 'Extra Bed Request',
    'Room Key Issue': 'Room Key Issue',
    'Phone Charger/Adapter/Cable Request': 'Phone Charger/Adapter/Cable Request',
    'Medical Supply Request': 'Medical Supply Request',
    'Towel Request': 'Towel Request',
    'Hairdryer Request': 'Hairdryer Request',
    'Bathrobe Request': 'Bathrobe Request',
    'Slipper Request': 'Slipper Request',
    'Shampoo, Conditioner Refill Request': 'Shampoo, Conditioner Refill Request',
    'Toothbrush Request': 'Toothbrush Request',
    'Cotton Request': 'Cotton Request',
    'Shaving Kit Request': 'Shaving Kit Request',
    'Toilet Malfunction': 'Toilet Malfunction',
    'Water Pressure or Temperature Issue': 'Water Pressure or Temperature Issue',
    'Faucet Malfunction': 'Faucet Malfunction',
    'Towel Rack Issue': 'Towel Rack Issue',
    'Shower Header Issue': 'Shower Header Issue',
    'Check-in, Check-out Inquiry': 'Check-in, Check-out Inquiry',
    'Lost & Found Inquiry': 'Lost & Found Inquiry',
    'Directions and Local Area Information': 'Directions and Local Area Information',
    'General Room Information': 'General Room Information',
    'Extra Services, Events or Activity  Inquiry': 'Extra Services, Events or Activity  Inquiry',
    'Hotel Facilities Inquiry': 'Hotel Facilities Inquiry',
    'Nearby Pharmacy and Hospital Inquiry': 'Nearby Pharmacy and Hospital Inquiry',
    'Wake-up Call Request': 'Wake-up Call Request',
    'Laundry Request': 'Laundry Request',
    'Housekeeping Request': 'Housekeeping Request',
    'Package or Mail Request': 'Package or Mail Request',
    'Transportation or Taxi Booking Assistance': 'Transportation or Taxi Booking Assistance',
    'Pest Control': 'Pest Control',
    'Event or Activity Booking': 'Event or Activity Booking',
    'Late Check-out Request': 'Late Check-out Request',
    'Special Guest Requests': 'Special Guest Requests',
    'Carpentry Issue': 'Carpentry Issue',
    'Flooring & Tiling': 'Flooring & Tiling',
    'Structural Repair': 'Structural Repair',
    'Wallpaper issue': 'Wallpaper issue',
    'Paint Issue': 'Paint Issue',
    'Foul Odour': 'Foul Odour',
    'Elevator Malfunction': 'Elevator Malfunction',
    'Door Malfunction': 'Door Malfunction',
    'Sanitation Issue': 'Sanitation Issue',
    'Other': 'Other',
    'Silence Pool Outdoor (Main Pool)': 'Silence Pool Outdoor (Main Pool)',
    'Jacuzzi Pool': 'Jacuzzi Pool',
    'Kids Pool with Octopus': 'Kids Pool with Octopus',
    'Indoor Pool Indoor': 'Indoor Pool Indoor',
    'Indoor Kids Pool Indoor': 'Indoor Kids Pool Indoor',
    'Activity Pool Outdoor': 'Activity Pool Outdoor',
    'Aqua Pool Outdoor': 'Aqua Pool Outdoor',
    'Kids Pool with Elephant': 'Kids Pool with Elephant',
    'Royal Salon': 'Royal Salon',
    'Konferans Salon': 'Konferans Salon',
    'Committee 4': 'Committee 4',
    'Marina Wedding Banquet': 'Marina Wedding Banquet',
    'Panorama Wedding Banquet': 'Panorama Wedding Banquet',
    'Efes Salon': 'Efes Salon',
    'Committee 3': 'Committee 3',
    'Committee 5': 'Committee 5',
    'Committee 1': 'Committee 1',
    'Committee 2': 'Committee 2',
    'Indoor Pool': 'Indoor Pool',
    'Kids Pool Outdoor': 'Kids Pool Outdoor',
    'Cottage Room Pool': 'Cottage Room Pool',
    'Main Pool(Outdoor)': 'Main Pool(Outdoor)',
    'Sundeck Room Pool (Outdoor)': 'Sundeck Room Pool (Outdoor)',
    'Ballroom': 'Ballroom',
    'Boardroom': 'Boardroom',
    'Fuaye': 'Fuaye',
    'Piccione': 'Piccione',
    'Street': 'Street',
    'Tannery Garden': 'Tannery Garden',
    'Tannery X I': 'Tannery X I',
    'Tannery X II': 'Tannery X II',
    'Stone House': 'Stone House',
    'Meeting Room 1': 'Meeting Room 1',
    'Meeting Room 2': 'Meeting Room 2',
    'Meeting Room 3': 'Meeting Room 3',
    'Board Room Toplantı Odası': 'Board Room Toplantı Odası',
    'Business Center': 'Business Center',
    'GIO Rooftop Lounge Bar': 'GIO Rooftop Lounge Bar',
    'GIO Rooftop Restaurant': 'GIO Rooftop Restaurant',
    'Linnet Pattiserie Cafe': 'Linnet Pattiserie Cafe',
    'Breakfast Lounge': 'Breakfast Lounge',
    'Stone Tavern': 'Stone Tavern',
    'Vintage': 'Vintage',
    'Vineria': 'Vineria',
    'Tannery beer garden': 'Tannery beer garden',
    'Public': 'Public',
    'Sparrow Restaurant': 'Sparrow Restaurant',
    'Tanen': 'Tanen',
    'Tannery': 'Tannery',
    'Pool Snack': 'Pool Snack',
    'Pool Snack Bar': 'Pool Snack Bar',
    'linnet bar': 'linnet bar',
    '1st Floor Toilet Female': '1st Floor Toilet Female',
    '1st Floor Restroom Male': '1st Floor Restroom Male',
    '1st Floor Disabled Toilet': '1st Floor Disabled Toilet',
    'Pool Mr. Wc': 'Pool Mr. Wc',
    'Pool Ladies Wc': 'Pool Ladies Wc',
    'Pool Shower': 'Pool Shower',
    'Lobby Restroom Women': 'Lobby Restroom Women',
    'Lobby Restroom Male': 'Lobby Restroom Male',
    'Lobby Disabled Toilet': 'Lobby Disabled Toilet',
    'Gio Toilet Woman': 'Gio Toilet Woman',
    'Gio Toilet Male': 'Gio Toilet Male',
    'Old Town Tanneries Toilet Women': 'Old Town Tanneries Toilet Women',
    'Old Town Tanneries Tuvalet Erkek': 'Old Town Tanneries Tuvalet Erkek',
    'Dressing Room Women': 'Dressing Room Women',
    'Dressing Room Male': 'Dressing Room Male',
    'Toilet Woman': 'Toilet Woman',
    'Toilet Male': 'Toilet Male',
    'Toilet Disabled': 'Toilet Disabled',
    'Tuvalet Personnel': 'Tuvalet Personnel',
    'Corridors / Massage Rooms Corridor': 'Corridors / Massage Rooms Corridor',
    'Basement Personel Tuvalet ve Duş Kadın': 'Basement Personel Tuvalet ve Duş Kadın',
    'Basement Personel Tuvalet ve Duş Erkek': 'Basement Personel Tuvalet ve Duş Erkek',
    'Indoor Parking': 'Indoor Parking',
    'Open Parking Lot': 'Open Parking Lot',
    'Reception and Lobby': 'Reception and Lobby',
    'Lobby - Guest Relation': 'Lobby - Guest Relation',
    'Lobby-Winter Garden': 'Lobby-Winter Garden',
    'Lobby-Library': 'Lobby-Library',
    'Lobby-Hotel Entrance': 'Lobby-Hotel Entrance',
    'Lobby-Gio Entrance': 'Lobby-Gio Entrance',
    'Breakfast Room Staircase': 'Breakfast Room Staircase',
    'Guest Elevator': 'Guest Elevator',
    'Guest Elevator Car Parking Lift': 'Guest Elevator Car Parking Lift',
    'Security Gate': 'Security Gate',
    'Meeting Area': 'Meeting Area',
    'General Directorate Office': 'General Directorate Office',
    'Secretariat Office': 'Secretariat Office',
    'Accounting': 'Accounting',
    'FB Office': 'FB Office',
    'Sales Marketing Office': 'Sales Marketing Office',
    'Technical Office': 'Technical Office',
    'Front Office': 'Front Office',
    'Luggage Room': 'Luggage Room',
    'Housekeeping Office': 'Housekeeping Office',
    'Basement Warehouse': 'Basement Warehouse',
    'Steward Warehouse': 'Steward Warehouse',
    'Water Tank': 'Water Tank',
    'Meydanci Warehouse': 'Meydanci Warehouse',
    'Beverage Storage': 'Beverage Storage',
    'Dry Garbage': 'Dry Garbage',
    'Cold Garbage': 'Cold Garbage',
    'Pool Upper Floor Warehouse': 'Pool Upper Floor Warehouse',
    'Technical Warehouse': 'Technical Warehouse',
    'Mechanical Center': 'Mechanical Center',
    'Electric Main Panel': 'Electric Main Panel',
    'Indoor Pool Machine Room': 'Indoor Pool Machine Room',
    'Machinery Department (Next to Parking Lot)': 'Machinery Department (Next to Parking Lot)',
    'Spa Heat Recovery Device': 'Spa Heat Recovery Device',
    'Weak Current Room': 'Weak Current Room',
    'Main kitchen': 'Main kitchen',
    'Linnet Kitchen': 'Linnet Kitchen',
    'Breakfast Room Kitchen': 'Breakfast Room Kitchen',
    'Picchione Kitchen': 'Picchione Kitchen',
    'Gio Kitchen': 'Gio Kitchen',
    'Gio Kitchen - Cold Storage': 'Gio Kitchen - Cold Storage',
    'Vegetable cabinet': 'Vegetable cabinet',
    'Main Kitchen Butcher Shop Cabinets': 'Main Kitchen Butcher Shop Cabinets',
    'Patisserie Cabinets': 'Patisserie Cabinets',
    'Dining Hall Bain Marie': 'Dining Hall Bain Marie',
    'Dining Hall Air Conditioner': 'Dining Hall Air Conditioner',
    'Main Kitchen Dishroom': 'Main Kitchen Dishroom',
    'Main Kitchen Water Expenses': 'Main Kitchen Water Expenses',
    'Breakfast Room Focus and Grill': 'Breakfast Room Focus and Grill',
    'Breakfast Room Vertical Cabinet': 'Breakfast Room Vertical Cabinet',
    'Breakfast Room Ventilation': 'Breakfast Room Ventilation',
    'Gio Kitchen Cold Air': 'Gio Kitchen Cold Air',
    'Gio Kitchen Dishroom': 'Gio Kitchen Dishroom',
    'Stone Tavern Kitchen': 'Stone Tavern Kitchen',
    'Stone Tavern Dishwashing': 'Stone Tavern Dishwashing',
    'Spa Reception': 'Spa Reception',
    'Sauna': 'Sauna',
    'Hamam': 'Hamam',
    'Pouch Room': 'Pouch Room',
    'Steam Room': 'Steam Room',
    'Massage Rooms': 'Massage Rooms',
    'Plates Room': 'Plates Room',
    'Fitness': 'Fitness',
    'Laundry': 'Laundry',
    'Staff Trainning Hall': 'Staff Trainning Hall',
    'Efes Meeting Hall': 'Efes Meeting Hall',
    'Royal Hall Backstage': 'Royal Hall Backstage',
    'Pergamon': 'Pergamon',
    'Miletus': 'Miletus',
    'Priene Salon': 'Priene Salon',
    'Ephesus Salon': 'Ephesus Salon',
    'Conferance Saloon (Pergamon+Miletus)': 'Conferance Saloon (Pergamon+Miletus)',
    'Conferance Saloon (Miletus+Priene)': 'Conferance Saloon (Miletus+Priene)',
    'Conferance Saloon (Pergamon+Miletus+Priene)': 'Conferance Saloon (Pergamon+Miletus+Priene)',
    'Silence Children Pool': 'Silence Children Pool',
    'Ephesus': 'Ephesus',
    'Claros': 'Claros',
    'Miletos': 'Miletos',
    'Anaia': 'Anaia',
    'Priene': 'Priene',
    'Nysa': 'Nysa',
    'Alinda': 'Alinda',
    'Didyma': 'Didyma',
    'Efes Restaurant': 'Efes Restaurant',
    'Green Restaurant': 'Green Restaurant',
    'Marina Restaurant': 'Marina Restaurant',
    'Teras Restaurant': 'Teras Restaurant',
    'Panorama Restaurant': 'Panorama Restaurant',
    'Efe Cafe & Patisserie': 'Efe Cafe & Patisserie',
    'Pine Street Waffle House': 'Pine Street Waffle House',
    'Fuaye Cafe': 'Fuaye Cafe',
    'Amphıtheater Bar': 'Amphıtheater Bar',
    'Beach Bar': 'Beach Bar',
    'Fuaye Bar': 'Fuaye Bar',
    'Lescalier Bar': 'Lescalier Bar',
    'Marina Bar/Kitchen': 'Marina Bar/Kitchen',
    'Sunset Bar': 'Sunset Bar',
    'Pool Bar': 'Pool Bar',
    'Terrace Restaurant Short Bar': 'Terrace Restaurant Short Bar',
    'Terrace Restaurant Long Bar': 'Terrace Restaurant Long Bar',
    'Panaroma Bar/Kıtchen': 'Panaroma Bar/Kıtchen',
    'Tennis Court Bar': 'Tennis Court Bar',
    'Sail Bar': 'Sail Bar',
    'Olea Bar': 'Olea Bar',
    'Sunset Terrace Bar': 'Sunset Terrace Bar',
    'Aqua Bay Wc': 'Aqua Bay Wc',
    'Aqua Ladies Wc': 'Aqua Ladies Wc',
    'Aqua Shower Cabins': 'Aqua Shower Cabins',
    'Efes Restaurant Bay Wc': 'Efes Restaurant Bay Wc',
    'Efes Restaurant Ladies Wc': 'Efes Restaurant Ladies Wc',
    'General Directorate Wc': 'General Directorate Wc',
    'Hammam-Sauna Bay Wc': 'Hammam-Sauna Bay Wc',
    'Turkish Bath-Sauna Ladıes Wc': 'Turkish Bath-Sauna Ladıes Wc',
    'Marina Bay Wc': 'Marina Bay Wc',
    'Marina Ladies Wc': 'Marina Ladies Wc',
    'Male Wc Under Kitchen': 'Male Wc Under Kitchen',
    'Female Wc Under Kitchen': 'Female Wc Under Kitchen',
    'Behind the Front Office Mr. Wc': 'Behind the Front Office Mr. Wc',
    'Behind the Front Office Female WC': 'Behind the Front Office Female WC',
    'Panaroma Bay Wc': 'Panaroma Bay Wc',
    'Panaroma Lady Wc': 'Panaroma Lady Wc',
    'Royal Bay Wc': 'Royal Bay Wc',
    'Rolay Woman Wc': 'Rolay Woman Wc',
    'Tennis Court Bay Wc': 'Tennis Court Bay Wc',
    'Tennis Court Ladıes Wc': 'Tennis Court Ladıes Wc',
    'Turbulence Sub Bay Wc': 'Turbulence Sub Bay Wc',
    'Female Wc Under Turbulence': 'Female Wc Under Turbulence',
    '1. Lobby Bay Wc': '1. Lobby Bay Wc',
    '1. Lobby Female Wc': '1. Lobby Female Wc',
    '2. Lobby Bay Wc': '2. Lobby Bay Wc',
    '2.Lobby Ladies Wc': '2.Lobby Ladies Wc',
    '3. Lobby Bay Wc': '3. Lobby Bay Wc',
    '3. Lobby Female Wc': '3. Lobby Female Wc',
    'Sail Bar Bay Wc': 'Sail Bar Bay Wc',
    'Sail Bar Ladies Wc': 'Sail Bar Ladies Wc',
    'Outdoor Pool Shower Cabins': 'Outdoor Pool Shower Cabins',
    'Beach Changing Booth': 'Beach Changing Booth',
    'Cold Room Number 1 (Meat Deep)': 'Cold Room Number 1 (Meat Deep)',
    'Cold Room Number 2 (Meat And Fısh)': 'Cold Room Number 2 (Meat And Fısh)',
    'Cold Room 3 (Meat Coz.)': 'Cold Room 3 (Meat Coz.)',
    'Cold Room 4 (Vegetable)': 'Cold Room 4 (Vegetable)',
    'Cold Room 5 (Fruit)': 'Cold Room 5 (Fruit)',
    'Cold Room 6 (Watermelon)': 'Cold Room 6 (Watermelon)',
    'Cold Room 7 (Hot)': 'Cold Room 7 (Hot)',
    'Cold Room Number 8 (Cold Appetızer)': 'Cold Room Number 8 (Cold Appetızer)',
    'Cold Room 9 (Cold)': 'Cold Room 9 (Cold)',
    'Cold Room 10 (Breakfast)': 'Cold Room 10 (Breakfast)',
    'Cold Room 11 (Patisserie)': 'Cold Room 11 (Patisserie)',
    'Cold Room 12 (Ambr Etdeep)': 'Cold Room 12 (Ambr Etdeep)',
    'Cold Room 13 (Fish Deep)': 'Cold Room 13 (Fish Deep)',
    'No. 14 Cold Room (Warehouse Cold Room)': 'No. 14 Cold Room (Warehouse Cold Room)',
    'Cold Room Number 15 (Warehouse Cold Room)': 'Cold Room Number 15 (Warehouse Cold Room)',
    'Cold Room 16 (Chicken Deep)': 'Cold Room 16 (Chicken Deep)',
    'Cold Room 17 (Pastanedeep)': 'Cold Room 17 (Pastanedeep)',
    'Cold Room 18 (Terrace Lıquor)': 'Cold Room 18 (Terrace Lıquor)',
    'Cold Room 19 (Cop)': 'Cold Room 19 (Cop)',
    'Cold Room 20 (Staff Meter)': 'Cold Room 20 (Staff Meter)',
    'Cold Room 21 (Panorama)': 'Cold Room 21 (Panorama)',
    'Cold Room 22 (Marina)': 'Cold Room 22 (Marina)',
    'Center Building Market': 'Center Building Market',
    'Center Building Boutique': 'Center Building Boutique',
    'Center Building Hair Salon': 'Center Building Hair Salon',
    'Center Building Jewelry Store': 'Center Building Jewelry Store',
    'Central Building Game Hall': 'Central Building Game Hall',
    'Center Building Photographer': 'Center Building Photographer',
    'Pine Street Market': 'Pine Street Market',
    'Pine Street Vintage': 'Pine Street Vintage',
    'A Elevator': 'A Elevator',
    'B Elevator': 'B Elevator',
    'C Elevator': 'C Elevator',
    'Lobby Lift': 'Lobby Lift',
    'Beach Lift': 'Beach Lift',
    'Terrace Lift': 'Terrace Lift',
    'Main Kitchen Elevator': 'Main Kitchen Elevator',
    'Warehouse': 'Warehouse',
    'Main Warehouse': 'Main Warehouse',
    'Aquapark': 'Aquapark',
    'Anfi Theatre': 'Anfi Theatre',
    'Reverse Osmos': 'Reverse Osmos',
    'Beach': 'Beach',
    'Cabana': 'Cabana',
    'Wavegakıran': 'Wavegakıran',
    'Fitness Center': 'Fitness Center',
    'Door Security': 'Door Security',
    'Water Sports': 'Water Sports',
    'Tennis Courts': 'Tennis Courts',
    'Marangozhane': 'Marangozhane',
    'Marina': 'Marina',
    'Parking': 'Parking',
    'Efes Kitchen': 'Efes Kitchen',
    'Infirmary': 'Infirmary',
    'Turkish Bath-Sauna': 'Turkish Bath-Sauna',
    'Seat Balcony': 'Seat Balcony',
    'Tailor Shop': 'Tailor Shop',
    'Pine Club (4-7)': 'Pine Club (4-7)',
    'Pane Club (8-12)': 'Pane Club (8-12)',
    'Punky Club (13-17)': 'Punky Club (13-17)',
    'Panorama Sunbathing Area': 'Panorama Sunbathing Area',
    'Aqua Hınged Balcony': 'Aqua Hınged Balcony',
    'Scaffold': 'Scaffold',
    '1. Lobby': '1. Lobby',
    '2. Lobby': '2. Lobby',
    '3. Lobby': '3. Lobby',
    'Staff Housing Old Block': 'Staff Housing Old Block',
    'Staff Housing New Block': 'Staff Housing New Block',
    'Staff Housing D Block': 'Staff Housing D Block',
    'Staff Laundry': 'Staff Laundry',
    'Staff Canteen': 'Staff Canteen',
    'Personnel Area Boiler Room': 'Personnel Area Boiler Room',
    'Staff Cafeteria Kitchen': 'Staff Cafeteria Kitchen',
    "3100's Corridor": "3100's Corridor",
    "3200's Corridor": "3200's Corridor",
    "3300's Corridor": "3300's Corridor",
    "3400's Corridor": "3400's Corridor",
    "3500's Corridor": "3500's Corridor",
    "3600's Corridor": "3600's Corridor",
    "3700's Corridor": "3700's Corridor",
    "3800's Corridor": "3800's Corridor",
    "3900's Corridor": "3900's Corridor",
    'Main Kitchen Office': 'Main Kitchen Office',
    'Key Office': 'Key Office',
    'Animation Office': 'Animation Office',
    'Open Office': 'Open Office',
    'Technical Service Office': 'Technical Service Office',
    'IT Office': 'IT Office',
    'R&D Offıce': 'R&D Offıce',
    'Cost Control Office': 'Cost Control Office',
    'Bungalow 700S Floor Offıce': 'Bungalow 700S Floor Offıce',
    '1200S Floor Office': '1200S Floor Office',
    '3200S Floor Office': '3200S Floor Office',
    '3400S Hk Office': '3400S Hk Office',
    'Purchasing Offıce': 'Purchasing Offıce',
    'Sales And Marketing Office': 'Sales And Marketing Office',
    'Congress Sales Marketıng Offıce': 'Congress Sales Marketıng Offıce',
    'Offices Tea Room': 'Offices Tea Room',
    'General Office': 'General Office',
    'Security Office': 'Security Office',
    'Guest Relations Office': 'Guest Relations Office',
    'Housekeepıng Office': 'Housekeepıng Office',
    'Human Resources Office': 'Human Resources Office',
    'Accounting Office': 'Accounting Office',
    'Main Kitchen Dry Food Warehouse': 'Main Kitchen Dry Food Warehouse',
    'Main Kitchen': 'Main Kitchen',
    'Main Kitchen Dishwashing': 'Main Kitchen Dishwashing',
    'Main Kitchen Patisserie': 'Main Kitchen Patisserie',
    'Amphitheater Bar Dj Booth': 'Amphitheater Bar Dj Booth',
    'Efes Dishwashers': 'Efes Dishwashers',
    'Indoor Pool Engine Room': 'Indoor Pool Engine Room',
    'Furnace Boiler Room': 'Furnace Boiler Room',
    'Under Hotel Boiler Room': 'Under Hotel Boiler Room',
    'Air Handling Units': 'Air Handling Units',
    'Elevator Panel Room': 'Elevator Panel Room',
    'Royal Salon Panel Room': 'Royal Salon Panel Room',
    'System Room': 'System Room',
    'Ice Machine Room': 'Ice Machine Room',
    'Generator(Under Terrace Restaurant)': 'Generator(Under Terrace Restaurant)',
    'Generator(Under Hotel Boıler)': 'Generator(Under Hotel Boıler)',
    'Generator(Staff Area)': 'Generator(Staff Area)',
    'Laundry Pump Tank': 'Laundry Pump Tank',
    'Iron/Welding Workshop': 'Iron/Welding Workshop',
    'Broadcast Room': 'Broadcast Room',
    'Teshin Center': 'Teshin Center',
    'Cooling Towers': 'Cooling Towers',
    'Open Pool Machine Room': 'Open Pool Machine Room',
    'Tennis Court Main Distribution Panel': 'Tennis Court Main Distribution Panel',
    'Superior Club': 'Superior Club',
    'Standart Club': 'Standart Club',
    'Club Studio': 'Club Studio',
    'Infinity Villa': 'Infinity Villa',
    'Business Suite': 'Business Suite',
    'Standart Suite with Terrace': 'Standart Suite with Terrace',
    'Junior Room with Sea View': 'Junior Room with Sea View',
    'Superior Suite with Pool': 'Superior Suite with Pool',
    'Large Land View': 'Large Land View',
    'Superior Suite': 'Superior Suite',
    'Std. Suite with Terrace&Garden': 'Std. Suite with Terrace&Garden',
    'Room with Garden View': 'Room with Garden View',
    'Standard Room Pool Garden View': 'Standard Room Pool Garden View',
    'Room with Sea View': 'Room with Sea View',
    'Standart Suite': 'Standart Suite',
    'Superior Room with Sea View': 'Superior Room with Sea View',
    'Villa': 'Villa',
    'Junior Club': 'Junior Club',
    'Pool': 'Pool',
    'Komite 4 Salonu': 'Komite 4 Salonu',
    'Marina Düğün Alanı': 'Marina Düğün Alanı',
    'Panorama Düğün Alanı': 'Panorama Düğün Alanı',
    'Komite 3 Salonu': 'Komite 3 Salonu',
    'Komite 5 Salonu': 'Komite 5 Salonu',
    'Komite 1 Salonu': 'Komite 1 Salonu',
    'Komite 2 Salonu': 'Komite 2 Salonu',
    'Sun Deck': 'Sun Deck',
    'Standart Oda': 'Standart Oda',
    'Sehir ve Deniz Manzarali Oda': 'Sehir ve Deniz Manzarali Oda',
    'Marina Deluxe Oda': 'Marina Deluxe Oda',
    'Standart Oda/ Engelli Odasi': 'Standart Oda/ Engelli Odasi',
    'Suit': 'Suit',
    'Tas Ev': 'Tas Ev',
    'Pools': 'Pools',
    'Meeting Rooms': 'Meeting Rooms',
    'Food & Beverage': 'Food & Beverage',
    'General Areas/Restrooms': 'General Areas/Restrooms',
    'SPA ve WELLNESS': 'SPA ve WELLNESS',
    'Committee Meeting Room': 'Committee Meeting Room',
    'General Areas / Parking Lot': 'General Areas / Parking Lot',
    'General Areas/Lobby': 'General Areas/Lobby',
    'Public Areas/Stairs': 'Public Areas/Stairs',
    'General Areas/Elevator': 'General Areas/Elevator',
    'General Areas': 'General Areas',
    'Offices HT': 'Offices HT',
    'Warehouses': 'Warehouses',
    'Technical HT': 'Technical HT',
    'Kitchen': 'Kitchen',
    'Meeting Room': 'Meeting Room',
    'Cabanas': 'Cabanas',
    'Cafe & Restaurants': 'Cafe & Restaurants',
    'Bars': 'Bars',
    'Wc- Shower Dressing Cabins': 'Wc- Shower Dressing Cabins',
    'Cold Rooms': 'Cold Rooms',
    'Shops': 'Shops',
    'Elevators': 'Elevators',
    'Locations': 'Locations',
    'Staff Area': 'Staff Area',
    'Corridors': 'Corridors',
    'Offices PB': 'Offices PB',
    'Technical PB': 'Technical PB',
    'kuspbKVKK' : `
    

1. SCOPE

1.1. The purpose of this personal data protection policy (the "Policy") is the purpose of Göçtur Tourism Investment and Trade Inc., Pine Bay Holiday Resort Hotel ("Migration"); to process personal data in accordance with the regulations of The Personal Data Protection Act no. 6698 (the "Law"). Violation of the law will be dealt with seriously by Göçtur and will be evaluated within the scope of regulatory provisions and disciplinary procedures. For the purposes of the law, the following definitions will be based on:

1.1.1. Personal Data: Any information about a specific or ideterminable real person;

1.1.2. Processing of Personal Data: Any transactions taken on data such as obtaining, recording, storing, storing, modifying, reorganization, disclosure, transfer, transfer, transfer, inherit, make available, classify or prevent the use of Personal Data by means that are completely or partially automated or are not automated as part of any data registration system

1.1.3. Special Quality Personal Data: Biometric and genetic data on persons' race, ethnicity, political thought, philosophical belief, religion, sect or other beliefs, clothing, association, foundation or union membership, health, sex life, criminal conviction and security measures;

1.1.4. Data Manager: Any real or legal person responsible for establishing and managing the data registration system, which determines the purposes and means for processing personal data, and Migration;

1.1.5. Data Processing: A third real or legal person who processes Personal Data on his behalf based on the authority granted by Göçtur;

1.1.6. Data Owner: The person with the actual personal data processed;

1.1.7. Data Registration System: The registration system in which personal data used by Göçtur is structured and processed according to certain criteria;

1.1.8. Board: Personal Data Protection Board;

1.1.9. Institution: Personal Data Protection Authority;

1.1.10. Law: The Personal Data Protection Act no. 6698, dated April 7, 2016 and published in the Official Gazette no. 29677.

1.2. With this Policy, Migration aims to inform the Data Owner and its contents are as follows:

1.2.1. Content and categories of Personal Data collected by Göçtur; use and transfer options;

1.2.2. Ways in which Personal Data is processed;

1.2.3. Ways in which Personal Data is retained; 1.2.4. Rights of Personal Data Holders;

1.2.5. Measures taken to protect Personal Data; 2. Principles for The Processing of Personal Data

2.1. The purpose of Göçtur is the registration of the relevant trade registry directorate and the entire purpose specified in the original contract declared.

2.2. In relation to the purpose of Migration; Personal Data that may be collected and processed from Board Members, customers, visitors, website users, employees, branches, third party agents, suppliers and their officials is listed below and this list may be expanded for Migration purposes. (The Following Personal Data varies by Data Owner contact group):

2.2.1. Identification card, driver's license, passport, registration sample, information on military status and nationality, passport photo, residence certificate and/or physical or digital surrogates;

2.2.2. Bank account information, billing information, credit card information;

2.2.3. Tax office information, tax number, tax deduction certificate;

2.2.4. Date of birth and place of birth information, parents' names;

2.2.5. Contact information, business card, etc. of all kinds of addresses, telephones, e-mails, etc.;

2.2.6. General health report, document that shows blood type scorecards or blood type, information of drugs used continuously, hepatitis-b and HIV HBS test results, lung film report, portor examination result document, disability, disability, disability health report, disability card and other documents and information on these documents;

2.2.7. The first and last name of the employee's dependents;

2.2.8. Contact information of the data owner regarding the emergency person;

2.2.9. Learning status and foreign grammar, diploma/ professional certificate surrogates

2.2.10. Photo and video recordings taken at events such as presentation, training, meeting, seminar, animation, etc. and recorded for security reasons;

2.2.11. Various information regarding criminal convictions and security measures, including criminal record;

2.2.12. Employee workplace entry and exit records and face scanning;

2.2.13. Social Security Institution number;

2.2.14. Vehicle license plate;

2.2.15. Any official document confirming the data owner's signature;

2.2.16. Website login, login and navigation information, password and password information, IP address;

2.2.17. Room number, information of the persons staying with;

2.2.18. Legal dispute information

2.3. Göçtur undertakes to process Personal Data only within the framework of the purposes and basis set out below, with the exceptions held in KVKK md. 5(2)(c);

2.3.1. Delivery of accommodation;

2.3.2. Newsletter subscription;

2.3.3. Pool and aquapark service;

2.3.4. The delivery of restaurants and catering services;

2.3.5. Conducting the necessary business and operational processes in order to benefit from the products and services offered;

2.3.6. Customer satisfaction, quality control, management of feedback processes, management of customer relations;

2.3.7. Conducting the necessary works and business processes for the privatisation and issuance of products and services;

2.3.8. To make the necessary efforts to recommend and promote the products and services offered to individuals by customizing them according to the likes, usage habits and needs of the persons of their own;

2.3.9. Operations and promotion allotments and campaign arrangements;

2.3.10. Organization of events such as weddings, invitations, congresses, seminars, trainings, meetings, etc.;

2.3.11. Use of previously obtained data in renewed transactions;

2.3.12. Resolution of commercial and/or legal disputes;

2.3.13. Transfer of data to domestic servers for the purpose of ensuring data security, archive, backup, statistical and security of data;

2.3.14. Management of external and internal audit, accounting, tax processes;

2.3.15. Provision of internal data transfer;

2.3.16. Future commercial and organizational planning, implementation of integrated management system;

2.3.17. Follow-up of past studies;

2.3.18. Ensuring order and control, management, compliance at work;

2.3.19. Planning and execution of human resources processes, conducting the activities of employees; managing the recruitment process and facilitating the process process;

2.3.20. Delivery of THE SERVICE,

2.3.21. Marketing, development of new products and services,

2.3.22. Management and execution of advertising, campaigns, promotional processes, execution of promotional activities;

2.3.23. Execution of communication activities, sending congratulatory messages and emails on national and religious holidays and special days,

2.3.24. Conducting marketing analysis studies;

2.3.25. Development of products and services, execution of works in this direction;

2.3.26. Collection with Virtual Pos;

2.3.27. Execution, planning, infrastructure of information security processes;

2.3.28. Ensuring the safety of physical space;

2.3.29. Creation of visitor records;

2.3.30. Ensuring the safety of fixtures and/or resources;

2.3.31. Ensuring the security of corporate operations; 3. Data Collection Method Migration will collect Personal Data in the following ways:

3.1. E-mail;

3.2. Fax;

3.3. Telephone;

3.4. SMS;

3.5. Mail;

3.6. Courier;

3.7. The website and social media accounts of Göçtur and its branches, Virtual Environments;

3.8. Through tourism agencies physically and/or digitally;

3.9. Hand delivery;

3.10. Contracts, application forms.

4. Processing and Transfer Permit

4.1. Domestic Processing and Transfer: Migration's processing of Personal Data domestically and transferring it to third real and legal persons is possible with the clear consent of the person (Data Owner) and will only be carried out in the presence of the following conditions:

4.1.1. As expressly stipulated in the law;

4.1.2. The person who is in a position to explain his consent due to actual impossibility or who has no legal validity for his or her consent must be required to protect the life or body integrity of himself or someone else;

4.1.3. The processing of Personal Data belonging to the parties to the contract is necessary, as long as it is directly related to the establishment or performance of a contract;

4.1.4. It is mandatory for Göçtur to fulfill its legal obligations;

4.1.5. The person concerned has been made a name for himself or her;

4.1.6. Data processing is mandatory for the facility, use or protection of a right;

4.1.7. The processing of data for the legitimate interests of Migration and/or other Data Officer is mandatory, without harming the fundamental rights and freedoms of the person concerned.

4.2. Processing and Transferring Specially Qualified Personal Data:

4.2.1. Göçtur may only process and transfer privately qualified Personal Data at home with the clear consent of the Data Holder.

4.2.2. Personal Data not related to health and sex life may be processed without the clear consent of the person in accordance with the law.

4.2.3. Personal Data on health and sex life may only be processed without the clear consent of persons or competent institutions and organizations under the obligation to keep secrets for the purpose of protecting public health, preventive medicine, medical diagnosis, the execution of treatment and care services, planning and management of health services and financing.

4.3. Processing and Transfer of Personal Data Abroad:

4.3.1. Migration is only with the clear consent of the Data Owners to process and transfer Personal Data abroad.

4.3.2. Migration above

The availability of the terms set forth in 4.1. and 4.2. and the presence of the following in the foreign country to which personal data will be transferred may transfer Personal Data abroad without the clear consent of the Data Owner;

4.3.3. Adequate protection in the foreign country to which personal data will be transferred;

4.3.4. In the event that there is not sufficient protection, Göçtur and its data persons in the relevant foreign country may transfer Personal Data abroad if they undertake an adequate protection in writing and the Board has permission.

4.3.5. In cases where the interests of Turkey or the relevant Data Holder will be severely damaged, except with the opinion of the relevant public institution or institution, it may be transferred abroad with the permission of the Board.

5. Security of Personal Data

5.1. Migration will ensure the security of Personal Data for the following purposes and will take all necessary technical and administrative measures to ensure the appropriate level of security for these purposes:

5.1.1. Prevent unlawful processing of Personal Data;

5.1.2. Prevent unlawful access to Personal Data;

5.1.3. To ensure the protection of Personal Data

5.2. Migration, in the case of the processing of Personal Data by another legal entity on its behalf,

5.1. Together with these other Data Processers, it is jointly responsible for taking the measures set out in section 5.1.

5.3. Migration must do or do the necessary inspections in its own institution or organization in order to ensure the implementation of the provisions of the Law.

5.4. Those who process data through Migration may not explain the Personal Data they have learned to anyone other than for the purpose of processing it, contrary to the provisions of the Law. This obligation continues after the end of their duties.

5.5. If the Personal Data processed is obtained by others by unlawful means, Göçtur will report this to the Data Owner and the Board within 72 hours. The Board may declare this situation on its website or in any other way it may see fit if it is appropriate.

6. Data Owner's Rights

6.1. Everyone has the following rights regarding him by applying to Göçtur.

6.1.1. Find out if your Personal Data has been processed;

6.1.2. Request information about personal data if processed;

6.1.3. To find out the purpose for which personal data is being processed and whether it is being used in accordance with their purpose;

6.1.4. Knowing the third parties to which Personal Data is transferred at home or abroad;

6.1.5. Request correction of Personal Data if it is incomplete or incorrectly processed;

6.1.6. Request the deletion or destruction of Personal Data within the framework of KVKK m. 6;

6.1.7. Request that transactions made in accordance with sections m. 6.1.5. and m. 6.1.6 of this Policy be reported to third parties to which Personal Data is transferred;

6.1.8. Object to the emergence of a result against the Data Owner itself by analyzing the Processed Personal Data exclusively through automated systems and

6.1.9. Claim to remedy damages if personal data is damaged by unlawful processing.

6.2. In order to use the rights set out in 6.1.

6.2.1. to kvkk@goctur.com.tr address, via e-mail; or 6.2.2. "Bayraklidede Mah. Kuştur Cd. No:25 09400 Kusadasi / Aydin / Turkey" address by mail. 7. Measures for The Accurate and Current Preservation of Personal Data Migration maintains Personal Data accurately and up-to-date in the following ways:

7.1. Daily backups;

7.2. Firewall;

7.3. Antivirus programs;

7.4. Restrictions on encryption systems and powers in virtual media access;

7.5. Card, keyed and encrypted entry systems to rooms and cabinets;

7.6. Confidentiality agreements and privacy undert underties

8. Changes to the Personal Data Protection Policy are Migration, which may make changes to this Policy to the extent required by activities or from a legal point of view. The text of the Policy, which has undergone such http://www.pinebay.com/ with the sharing of the text on the website.

    `,
    'xkukuKVKK' : `
    GÖÇTUR
DOUBLETREE BY HILTON KUŞADASI
KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI
1. Kapsam
1.1. İşbu kişisel verilerin korunması politikasının (“Politika”) amacı, Göçtur Turizm
Yatırım ve Ticaret A.Ş, Doubletree By Hilton Kuşadası Hotel’in (“Göçtur”); kişisel
verileri, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) düzenlemeleri
ile uyumlu olarak işlemesini sağlamaktır. Kanunun ihlali Göçtur tarafından ciddi bir
şekilde ele alınacak olup, mevzuat hükümleri ve disiplin prosedürleri kapsamında
değerlendirilecektir. Kanunun amaçları çerçevesinde, aşağıdaki tanımlar esas
alınacaktır:
1.1.1. Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi;
1.1.2. Kişisel Verilerin İşlenmesi: Kişisel Verilerin tamamen veya kısmen otomatik olan
ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi,
yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle
getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde
gerçekleştirilen her türlü işlemi;
1.1.3. Özel Nitelikli Kişisel Veriler: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi
inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da
sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle
ilgili verileri ile biyometrik ve genetik verilerini;
1.1.4. Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri
kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan herhangi bir gerçek
veya tüzel kişiyi ve Göçtur’u;
1.1.5. Veri İşleyen: Göçtur tarafından verilen yetkiye dayanarak onun adına Kişisel
Verileri işleyen üçüncü bir gerçek veya tüzel kişiyi;
1.1.6. Veri Sahibi: Kişisel Verileri işlenen gerçek kişiyi;
1.1.7. Veri Kayıt Sistemi: Göçtur tarafından kullanılan Kişisel Verilerin belirli kriterlere
göre yapılandırılarak işlendiği kayıt sistemini;
1.1.8. Kurul: Kişisel Verileri Koruma Kurulunu;
1.1.9. Kurum: Kişisel Verileri Koruma Kurumunu;
1.1.10. Kanun: 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayınlanmış olan
6698 sayılı Kişisel Verilerin Korunması Kanunu’nu ifade etmektedir.
1.2. İşbu Politika ile Göçtur, Veri Sahibini bilgilendirmeyi amaçlamaktadır ve içeriği
aşağıdaki şekildedir:
1.2.1. Göçtur tarafından toplanan Kişisel Verilerin içerik ve kategorileri; kullanılış ve
aktarım seçenekleri;
1.2.2. Kişisel Verilerin işleniş biçimleri;
1.2.3. Kişisel Verilerin muhafaza edilme biçimleri;
1.2.4. Kişisel Veri Sahiplerinin hakları;
1.2.5. Kişisel Verilerin korunmasına ilişkin alınan önlemler;
2. Kişisel Verinin İşlenmesine Dair Esaslar
2.1. Göçtur’un amacı, ilgili ticaret sicil müdürlüğü nezdinde tescil ve ilan edilmiş esas
sözleşmesinde belirtilmiş olan amaçların bütünüdür.
2.2. Göçtur’un amacı ile ilişkili olarak; Yönetim Kurulu Üyeleri, müşterileri, ziyaretçileri,
internet sitesi kullanıcıları, çalışanları, şubeleri, üçüncü kişi acenteler, tedarikçileri ve
bunların yetkililerinden toplanabilecek ve işlenebilecek Kişisel Veriler aşağıda sıralanır
ve bu liste Göçtur amaçları doğrultusunda genişletilebilir. (Aşağıda belirtilen Kişisel
Veriler Veri Sahibi kişi grubuna göre farklılık göstermektedir):
2.2.1. Nüfus cüzdanı, sürücü belgesi, pasaport, nüfus kayıt örneği, askerlik durumuna ve
milliyetine ilişkin bilgi, vesikalık fotoğraf, ikametgah belgesi ve/veya bunların fiziki
veya dijital suretleri;
2.2.2. Banka hesap bilgisi, fatura bilgisi, kredi kartı bilgisi;
2.2.3. Vergi dairesi bilgisi, vergi numarası, vergi indirim belgesi;
2.2.4. Doğum tarihi ve doğum yeri bilgisi, anne baba adları;
2.2.5. Her türlü adres, telefon, e-posta vb. iletişim bilgisi, kartvizit;
2.2.6. Genel sağlık raporu, kan grubu karneleri veya kan grubunu gösterir belge, varsa
sürekli kullandığı ilaçların bilgisi, hepatit-b ve HIV HBS test sonuçları, akciğer film
raporu, portör muayene sonuç belgesi alerji ve beslenme alışkanlıklarının bilgisi,
özürlülük, sakatlık, engellilik durumunu gösterir sağlık raporu, engelli kartı ve sair
belgeler ve bu belgeler üzerindeki bilgiler;
2.2.7. Çalışanın bakmakla yükümlü olduğu kişilerin adı ve soyadı;
2.2.8. Veri sahibinin acil durum kişisine ilişkin iletişim bilgisi;
2.2.9. Öğrenim durumu ve yabancı dil bilgisi, diploma/ mesleki sertifika suretleri
2.2.10. Sunum, eğitim, toplantı, seminer, animasyon vb. etkinliklerde çekilen ve güvenlik
nedeniyle kaydedilen fotoğraf ve video kayıtları;
2.2.11. Ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili, sabıka kaydı dâhil olmak üzere
çeşitli bilgiler;
2.2.12. Çalışanların işyeri giriş çıkış kayıtları ve yüz taraması;
2.2.13. Sosyal Güvenlik Kurumu numarası;
2.2.14. Araç plakası;
2.2.15. Veri sahibinin imzasını tasdik eder nitelikte herhangi bir resmi belge;
2.2.16. İnternet sitesi giriş, çıkış ve gezinme bilgisi, şifre ve parola bilgisi, IP adresi;
2.2.17. Oda numarası, birlikte konakladığı kişilerin adı, soyadı, iletişim bilgileri, TC
kimlik numarası bilgisi;
2.2.18. Hukuki uyuşmazlık bilgileri.
2.3. Göçtur, KVKK md. 5(2)(c)’de düzenlenen istisnalar hariç olmak kaydıyla, Kişisel
Verileri sadece aşağıda belirtilen amaç ve dayanaklar çerçevesinde işleyeceğini taahhüt
etmektedir;
2.3.1. Konaklama hizmetinin sunulması;
2.3.2. Bülten aboneliği;
2.3.3. Havuz ve aquapark hizmetinin sunulması;
2.3.4. Restoran ve catering hizmetinin sunulması;
2.3.5. Sunulan ürün ve hizmetlerden faydalandırılabilmesi için gerekli iş ve operasyonel
süreçlerin yürütülmesi;
2.3.6. Müşteri memnuniyeti, kalite kontrol, geri bildirim süreçlerinin yönetimi, müşteri
ilişkilerinin yönetimi;
2.3.7. Ürün ve hizmetlerin özelleştirilerek verilmesi için gerekli çalışmaların ve iş
süreçlerinin yürütülmesi;
2.3.8. Sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve
ihtiyaçlarına göre özelleştirilerek kişilere önerilmesi ve tanıtılması için gerekli
çalışmaların yapılması;
2.3.9. Operasyon ve tanıtım faaliyetleri ve kampanya düzenleme;
2.3.10. Düğün, davet, kongre, seminer, eğitim, toplantı vb. etkinliklerin organizasyonu;
2.3.11. Daha önceden elde edilmiş verilerin yenilenen işlemlerde kullanılması;
2.3.12. Ticari ve/veya hukuki uyuşmazlıkların çözümü;
2.3.13. Veri güvenliğinin temini amacıyla verilerin yurtiçi serverlara aktarılması, verilerin
arşivi, yedeklenmesi, istatistiki ve güvenliği;
2.3.14. Dış ve iç denetim, muhasebe, vergi süreçlerinin yönetimi;
2.3.15. İç veri aktarımının sağlanması;
2.3.16. Geleceğe dönük ticari ve organisazyonel planlama yapılması, entegre yönetim
sisteminin uygulanması;
2.3.17. Geçmiş çalışmaların takibi;
2.3.18. İş yerinde düzen ve kontrol, yönetim, uyumun sağlanması;
2.3.19. İnsan kaynakları süreçlerinin planlanması ve icrası, çalışanların özlük
faaliyetlerinin yürütülmesi; işe alım sürecinin yönetilmesi ve süreç işleyişinin
kolaylaştırılması;
2.3.20. WİFİ hizmetinin sunulması,
2.3.21. Pazarlama, yeni ürün ve hizmetlerin geliştirilmesi,
2.3.22. Reklam, kampanya, promosyon süreçlerinin yönetimi ve yürütülmesi, tanıtım
faaliyetlerinin yürütülmesi;
2.3.23. İletişim faaliyetlerinin icrası, ulusal ve dini bayram ve özel günlerde tebrik mesaj
ve e-postaların gönderilmesi,
2.3.24. Pazarlama analiz çalışmalarının yürütülmesi;
2.3.25. Ürün ve hizmetlerin geliştirilmesi, bu yönde çalışmaların yürütülmesi;
2.3.26. Sanal Pos ile tahsilat yapılması;
2.3.27. Bilgi güvenliği süreçlerinin icrası, planlanması, alt yapı oluşturulması;
2.3.28. Fiziksel mekân güvenliğinin temini;
2.3.29. Ziyaretçi kayıtlarının oluşturulması;
2.3.30. Demirbaşların ve/veya kaynakların güvenliğinin temini;
2.3.31. Kurum operasyonlarının güvenliğinin temini;
3. Veri Toplama Yöntemi
Göçtur, Kişisel Verileri aşağıdaki belirtilen yöntemler ile toplayacaktır:
3.1.1. Elektronik posta;
3.1.2. Faks;
3.1.3. Telefon;
3.1.4. SMS;
3.1.5. Posta;
3.1.6. Kurye;
3.1.7. Göçtur ve şubelerinin internet sitesi ve sosyal medya hesapları, Sanal Ortamlar;
3.1.8. Turizm acentelerinin aracılığıyla fiziken ve/veya dijital yollarla;
3.1.9. Elden teslim;
3.1.10. Sözleşmeler, başvuru formları.
4. İşleme ve Aktarım İzni
4.1. Yurtiçinde İşleme ve Aktarım:
Göçtur’un Kişisel Verileri yurtiçinde işlemesi ve üçüncü gerçek ve tüzel kişilere aktarımı
ilgili kişinin (Veri Sahibinin) açık rızası ile mümkün olup açık rıza yoksa ancak aşağıdaki
şartların varlığında gerçekleştirilecektir:
4.1.1. Kanunlarda açıkça öngörülmesi;
4.1.2. Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına
hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya
beden bütünlüğünün korunması için zorunlu olması;
4.1.3. Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
sözleşmenin taraflarına ait Kişisel Verilerin işlenmesinin gerekli olması;
4.1.4. Göçtur’un hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması;
4.1.5. İlgili kişinin kendisi tarafından alenileştirilmiş olması;
4.1.6. Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması;
4.1.7. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Göçtur ve/ veya
diğer Veri Sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.
4.2. Özel Nitelikli Kişisel Verilerin İşleme ve Aktarımı:
4.2.1. Göçtur özel nitelikli Kişisel Verileri ancak Veri Sahibinin açık rızası ile işleyip
yurtiçinde aktarabilir.
4.2.2. Sağlık ve cinsel hayata ilişkin olmayan Kişisel Veriler, kanunlarda öngörülen
hâllerde ilgili kişinin açık rızası aranmaksızın işlenebilir.
4.2.3. Sağlık ve cinsel hayata ilişkin Kişisel Veriler ise ancak kamu sağlığının korunması,
koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık
hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama
yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından
ilgilinin açık rızası aranmaksızın işlenebilir.
4.3. Yurtdışında Kişisel Veri İşleme ve Aktarım:
4.3.1. Göçtur ancak Veri Sahiplerinin açık rızası ile Kişisel Verileri yurtdışında
işleyebilir ve aktarabilir.
4.3.2. Göçtur yukarıda 4.1. ve 4.2.’de belirtilen şartların mevcudiyeti ve kişisel verinin
aktarılacağı yabancı ülkede aşağıda belirtilen hususların bulunması halinde Veri
Sahibinin açık rızası olmaksızın Kişisel Verileri yurt dışına aktarabilir;
a. Kişisel Verinin aktarılacağı yabancı ülkede yeterli korumanın bulunması;
b. Yeterli korumanın bulunmaması durumunda Göçtur’un ve ilgili yabancı ülkedeki
veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun
izninin bulunması halinde Göçtur Kişisel Verileri yurt dışına aktarabilir.
4.3.3. Uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye’nin veya ilgili Veri
Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, Göçtur ancak
ilgili kamu kurum veya kuruluşunun görüşünü alarak Kurulun izniyle yurt dışına
aktarılabilir.
5. Kişisel Verilerin Güvenliği
5.1. Göçtur, Kişisel Verilerin güvenliğini aşağıdaki amaçları gerçekleştirmek için
sağlayacak ve işbu amaçları sağlamak için uygun güvenlik düzeyini temin etmeye
yönelik gerekli her türlü teknik ve idari tedbirleri alacaktır:
5.1.1. Kişisel Verilerin hukuka aykırı olarak işlenmesini önlemek;
5.1.2. Kişisel Verilere hukuka aykırı olarak erişilmesini önlemek;
5.1.3. Kişisel Verilerin muhafazasını sağlamak.
5.2. Göçtur, Kişisel Verilerin kendi adına başka bir gerçek veya tüzel kişi tarafından
işlenmesi hâlinde, bu Politikanın 5.1. bölümünde belirtilen tedbirlerin alınması
hususunda bu diğer Veri İşleyenlerle birlikte müştereken sorumludur.
5.3. Göçtur, kendi kurum veya kuruluşunda, Kanun hükümlerinin uygulanmasını sağlamak
amacıyla gerekli denetimleri yapmak veya yaptırmak zorundadır.
5.4. Göçtur ile Veri İşleyenler, öğrendikleri Kişisel Verileri Kanun hükümlerine aykırı
olarak başkasına açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük
görevlerinin sona ermesinden sonra da devam eder.
5.5. İşlenen Kişisel Verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi
hâlinde, Göçtur bu durumu 72 saat içerisinde Veri Sahibine ve Kurula bildirir. Kurul,
gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir
yöntemle ilan edebilir.
6. Veri Sahibinin Hakları
6.1. Herkes Göçtur’a başvurarak kendisiyle ilgili aşağıdaki haklara sahiptir.
6.1.1. Kişisel Verilerinin işlenip işlenmediğini öğrenme;
6.1.2. Kişisel Verileri işlenmişse buna ilişkin bilgi talep etme;
6.1.3. Kişisel Verilerin işlenme amacını ve bunların amacına uygun kullanılıp
kullanılmadığını öğrenme;
6.1.4. Yurt içinde veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri bilme;
6.1.5. Kişisel Verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
isteme;
6.1.6. Kanunun 7. maddesi çerçevesinde Kişisel Verilerin silinmesini veya yok edilmesini
isteme;
6.1.7. 6.1.5. ve 6.1.6 bölümleri uyarınca yapılan işlemlerin, Kişisel Verilerin aktarıldığı
üçüncü kişilere bildirilmesini isteme;
6.1.8. İşlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
suretiyle Veri Sahibinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve
6.1.9. Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
zararın giderilmesini talep etme.
6.2. 6.1.’de belirtilen hakların kullanılması için Kişiler Verilere ilişkin olarak ilgili kişilerin
kimliklerinin tespitini sağlayacak bilgiler ile birlikte söz konusu taleplerin aşağıdaki
iletişim yollarından yararlanarak yazılı şekilde iletilmesi gerekmektedir:
6.2.1. kvkk@goctur.com.tr adresine, elektronik posta vasıtasıyla; veya
6.2.2. “Bayraklıdede Mah. Kuştur Cd. No:25 09400 Kuşadası / Aydın / Türkiye”
adresine posta vasıtasıyla.
7. Kişisel Verilerin Doğru ve Güncel Olarak Muhafaza Edilmesine İlişkin Önlemler
Göçtur, Kişisel Verileri aşağıdaki yöntemler dâhilinde doğru ve güncel olarak muhafaza
etmektedir:
7.1. Günlük yedeklemeler;
7.2. Firewall;
7.3. Antivirüs programları;
7.4. Sanal ortam erişiminde şifreleme sistemleri ve yetkilerde kısıtlamalar;
7.5. Oda ve dolaplara kartlı, anahtarlı ve şifreli giriş sistemleri;
7.6. Gizlilik sözleşmeleri ve gizlilik taahhütnameleri.
8. Kişisel Verilerin Korunması Politikasında Yapılacak Değişiklikler
Göçtur, işbu Politikada faaliyetlerin gerektirdiği ölçüde veya yasal açıdan gerekli olan
değişiklikleri yapabilir. Söz konusu değişikliğe uğramış olan Politika metninin
http://www.dtbyhiltonkusadasi.com sitesinde paylaşılması ile birlikte geçerlilik kazanacaktır.
    
    `,
    'cancel' : 'Cancel',
    'accept' : 'Accept',
    'kvkktitle' : 'Personal Data Protection Policy',
    'changelanguage' : 'Change Language',
    'thanksTitle' : 'Thank You!',
    'thanksSubTitle' : 'Thank you for your feedback.',
    'submitAgain'  : 'Resend the survey'
};

export default enJSON;
