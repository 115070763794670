

import ApiConstants from "src/constants/apiConstants";
import BaseHttpService from "../http/baseHttpService";
import { ResponseWrapper } from "src/models/responseWrapper";
import { ReportInformation } from "src/models/ReportInformation";

class ReportService {

    private _httpService: BaseHttpService;

    constructor() {
        this._httpService = new BaseHttpService(ApiConstants.ReportApi);
    }


    async getReports(): Promise<ResponseWrapper<ReportInformation[]>> {
        try {
            let response = await this._httpService.post<ResponseWrapper<any>>('/reportInformations/list', {
                pagination: {
                    maxRowsPerPage: 10000,
                    currentPage: 1
                }
            });

            return {
                success: response.data.success,
                message: response.data.message,
                data: response.data.data?.list
            }
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }

    }

    async getReport(reportId: number): Promise<ResponseWrapper<ReportInformation>> {
        try {
            let response = await this._httpService.post<ResponseWrapper<ReportInformation>>(`/reportInformations/get`, {
                reportInformationNameId: reportId
            });

            return response.data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }
    }

    async createReport(report: ReportInformation): Promise<ResponseWrapper<ReportInformation>> {
        try {
            let response = await this._httpService.post<ResponseWrapper<ReportInformation>>('/reportInformations/insert', report);

            return response.data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }
    }

    async updateReport(report: ReportInformation): Promise<ResponseWrapper<ReportInformation>> {

        try {
            let response = await this._httpService.post<ResponseWrapper<ReportInformation>>('/reportInformations/update', report);
            return response.data
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }
    }

    async deleteReport(reportId: number): Promise<ResponseWrapper<ReportInformation>> {
        try {
            let response = await this._httpService.post<ResponseWrapper<ReportInformation>>('/reportInformations/delete', {
                reportInformationNameId: reportId
            });

            return response.data;
        } catch (error) {
            return {
                success: false,
                message: error.message,
                data: null

            }
        }
    }




    async getReportsAsMenuItem(): Promise<ResponseWrapper<any[]>> {

        try {
            let response = await this._httpService.get<ResponseWrapper<any[]>>('/reportInformations/listAsMenuItem');

            return response.data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }

    }

    async getPowerBIReport(reportId: number): Promise<ResponseWrapper<any>> {

        try {
            let response = await this._httpService.get<ResponseWrapper<any>>(`/reportInformations/getPowerBIReport?reportId=${reportId}`);

            return response.data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }
    }

    async getEmbedCode(reportId: number): Promise<ResponseWrapper<any>> {

        try {
            let response = await this._httpService.get<ResponseWrapper<any>>(`/reportInformations/getEmbedCode?reportId=${reportId}`);

            return response.data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }


    }

    async getPowerBIDashboard(reportId: number): Promise<ResponseWrapper<any>> {
        try {
            const response = await this._httpService.get<ResponseWrapper<any>>(`/reportInformations/getPowerBIDashboard?reportId=${reportId}`);

            return response.data;

        } catch (error) {

            return {
                success: false,
                message: error.message,
                data: null
            }
        }

    }
}

export default new ReportService;