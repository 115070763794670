import { UserManager, User, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import ApiConstants from "src/constants/apiConstants";


const config: UserManagerSettings = {
    authority: ApiConstants.IdentityUrl,
    client_id: "processes.js",
    redirect_uri: `${window.location.origin}/callback`,
    response_type: "code",
    scope: 'openid profile genesisAPI',
    post_logout_redirect_uri: `${window.location.origin}/login`,

    // Number of seconds before the token expires to trigger
    // the `tokenExpiring` event
    accessTokenExpiringNotificationTime: 30,

    // Do we want to renew the access token automatically when it's
    // about to expire?
    automaticSilentRenew: true,

    // Do we want to filter OIDC protocal-specific claims from the response?
    filterProtocolClaims: true,
    //Get user data as response too
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),

    //prompt: 'login'
};

const userManager = new UserManager(config);

const AuthService = {
    getUser: (): Promise<User | null> => {
        return userManager.getUser();
    },
    login: (email: string, tenant?: number): Promise<void> => {
        return userManager.signinRedirect({ acr_values: `tenant:${tenant} username:${email}` });
    },
    logout: async (): Promise<void> => {
        const user = await userManager.getUser();
        if (!user) {
            return userManager.signoutRedirect();
        }
        return userManager.signoutRedirect({ id_token_hint: user.id_token })
    },
    getAccessToken: async (): Promise<string | null> => {
        const user = await userManager.getUser();

        return user?.access_token ?? null;
    },
    signinRedirectCallback: async (): Promise<User> => {
        var user = await userManager.signinRedirectCallback();

        return user;

    }
};

export default AuthService;