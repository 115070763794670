import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import { LicenseManager } from 'ag-grid-enterprise';
import { agGridKey } from './config';

LicenseManager.setLicenseKey(agGridKey);


ReactDOM.render(
  <HelmetProvider>
    {/* <Provider store={store}> */}
    <BrowserRouter>
      <ScrollTop />
      <App />
    </BrowserRouter>
    {/* </Provider> */}
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
