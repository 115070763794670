import { Suspense, lazy, ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


const lazyRetry = function(componentImport, name) {
  return new Promise<{default : ComponentType<any>}>((resolve,reject) => {

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed` || 'false')
    );

    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed` , 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed){
        window.sessionStorage.setItem(`retry-${name}-refreshed` , 'true')
        return window.location.reload();
      }
      reject(error);
    })
  })
}

// Pages

// Status

const Status404 = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Status/Status404') , "status404"))
);
const Status500 = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Status/Status500') , "status500"))
);
const StatusComingSoon = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Status/ComingSoon') , "comingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Status/Maintenance') , "maintenance"))
);
const FormPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/CreateForm'), "createForm"))
);
const baseRoutes = [
  {
    path: '500',
    element: <Status500 />
  },
  {
    path: 'maintenance',
    element: <StatusMaintenance />
  },
  {
    path: 'coming-soon',
    element: <StatusComingSoon />
  },
  {
    path: '*',
    element: <Navigate to={'404'} />
  },
  {
    path: '/404',
    element: <Status404 />
  },
  {
    path: '/processesv2/:processCode/new',
    element: <FormPage />
  },
  
  
];

export default baseRoutes;
