

import ApiConstants from "src/constants/apiConstants";
import BaseHttpService from "../http/baseHttpService";
import { ResponseWrapper } from "src/models/responseWrapper";

class IdentityService {

    private _httpService: BaseHttpService;

    constructor() {
        this._httpService = new BaseHttpService(ApiConstants.IdentityUrl);
    }

    async getResources(): Promise<ResponseWrapper<any>> {

        try {
            let response = await this._httpService.post<ResponseWrapper<any>>(`Account/GetResources`);
            return response.data;
        } catch (error) {
            return {
                success : false,
                data : null,
                message : error.message
            }    
        }

    }
}

export default new IdentityService;