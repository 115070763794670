import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  resourceCode?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export interface FilteredMenuItems {
  heading: string;
  items: MenuItem[];
}

export function searchMenuItems(menuItems: MenuItems[], query: string): FilteredMenuItems[] {
  const filteredItems: FilteredMenuItems[] = [];

  const queryLowerCase = query.toLocaleLowerCase('tr-TR');

  for (const menu of menuItems) {
    const { heading, items } = menu;
    const filteredSubItems: MenuItem[] = [];

    for (const item of items) {
      const itemNameLowerCase = item.name.toLocaleLowerCase('tr-TR');
      if (item.link && item.link.trim() !== '' && itemNameLowerCase.includes(queryLowerCase)) {
        filteredSubItems.push(item);
      }
      if (item.items) {
        const nestedResults = searchMenuItems([{ items: item.items, heading: '' }], query);
        if (nestedResults.length > 0) {
          filteredSubItems.push(...nestedResults[0].items);
        }
      }
    }

    if (filteredSubItems.length > 0) {
      filteredItems.push({ heading, items: filteredSubItems });
    }
  }

  return filteredItems;
}

export function flattenMenuItems(menuItems: MenuItems[]): MenuItem[] {
  let flatList: MenuItem[] = [];

  function recurseItems(items: MenuItem[]): void {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.items) {
        recurseItems(item.items);
      } else if (item.link) {
        flatList.push(item);
      }
    }
  }

  for (let i = 0; i < menuItems.length; i++) {
    recurseItems(menuItems[i].items);
  }

  return flatList;
}




const menuItems: MenuItems[] = [
];

export default menuItems;
