const deJSON = {
  'previous': 'Zurück',
  'next': 'Weiter',
  'submit': 'Absenden',
  'copyright': 'Alle Rechte vorbehalten.',
  'file_upload_button_tooltip': 'Datei hochladen',
  'uploadedFiles': 'Hochgeladene Dateien',
  'plsFillFieldsAlert': 'Bitte füllen Sie alle mit * markierten Felder aus',
  'yes': 'Ja',
  'no': 'Nein',
  'other': 'Andere',
  'general' : 'Allgemein',
  'cancel' : 'Stornieren',
  'accept' : 'Akzeptieren',
  'kvkktitle' : 'Datenschutzrichtlinie',
  'changelanguage' : 'Sprache ändern',
  'thanksTitle' : 'Vielen Dank!',
  'thanksSubTitle' : 'Vielen Dank für Ihr Feedback.',
  'submitAgain' : 'Fragebogen erneut senden',
  'question' : 'Frage',
  'detail' : 'Detail',
  'Main Option': 'Hauptoption',
  'Sub Option': 'Unteroption'
};

export default deJSON;
