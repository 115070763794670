import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTenant from 'src/hooks/useTenant';
import { Image } from '@mui/icons-material';
import useSidebar from '../../hooks/useSidebar';
import useAuth from '../../hooks/useAuth';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
        justify-content: center;
`
);


const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

interface ILogoProps {
  size?: 'small' | 'medium' | 'large';
}

const possibleImages = {
  3 : "/static/images/logo/logo_3.png",
  4 : "/static/images/logo/logo_4.png",
  5 : "/static/images/logo/logo_5.png"
}


function Logo({ size = 'medium' }: ILogoProps) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const width = size === 'small' ? 100 : size === 'medium' ? 150 : 200;
  const height = size === 'small' ? 50 : size === 'medium' ? 75 : 100;

  const tenantData = useTenant(selector => selector.tenant);

  const tenant = tenantData && !!tenantData.tenantId ?
      tenantData : {
        tenantId: 3,
        tenantName: 'Göçtur Turizm Yatırım ve Ticaret A.Ş.'
      };

  const shouldChangePassword = useAuth(selector => selector.user?.shouldChangePassword);
  const isAuthenticated = useAuth(selector=>  selector.isAuthenticated);

  const pathTo = isAuthenticated && shouldChangePassword ? "/change-password" : "/"

  return (
    <TooltipWrapper title={tenant.tenantName} arrow>
      <LogoWrapper to={pathTo}>
          <img
            //src={`/static/images/logo/logo_${tenant.tenantId}.png`}
            src={possibleImages[tenant.tenantId]}
            alt={tenant.tenantName}
            width={width}
            height={height}
            style={{ objectFit: 'contain' }}
            loading="eager"
          />
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
