import { Suspense, lazy, ComponentType } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';
import { AuthProvider } from 'src/contexts/OidcAuthContext';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const lazyRetry = function(componentImport, name) {
  return new Promise<{default: ComponentType<any>}>((resolve,reject) => {

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed` || 'false')
    );

    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed` , 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed){
        window.sessionStorage.setItem(`retry-${name}-refreshed` , 'true')
        return window.location.reload();
      }
      reject(error);
    })
  })
}

// Account

const LoginBasic = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Auth/Login') , "login"))
);

const Callback = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Auth/Callback') , "callback"))
);

const RecoverPassword = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Auth/RecoverPassword') , "recoverPassword"))
);

const ChangePassword = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/User/ChangePassword') , "changePassword"))
);

const ResetPassword = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Auth/ResetPassword') , "resetPassword"))
);


const accountRoutes = [
 

  {
    path: 'callback',
    element: (
      <Guest>
        <Callback />
      </Guest>
    )
  },
  {
    path: 'login',
    element: <Guest><LoginBasic /></Guest>
  },
  {
    path: 'recover-password',
    element: <Guest><RecoverPassword /></Guest>
  },
  {
    path: 'reset-password/:uniqueKey',
    element: <Guest><ResetPassword /></Guest>
  }

];

export default accountRoutes;
