import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';

import accountRoutes from './account';
import baseRoutes from './base';
import { Suspense, lazy, ComponentType } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { AuthProvider } from 'src/contexts/OidcAuthContext';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { TenantProvider } from 'src/contexts/TenantContext';
import Guest from 'src/components/Guest';


const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const lazyRetry = function(componentImport, name) {
  return new Promise<{default: ComponentType<any>}>((resolve,reject) => {

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed` || 'false')
    );

    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed` , 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed){
        window.sessionStorage.setItem(`retry-${name}-refreshed` , 'true')
        return window.location.reload();
      }
      reject(error);
    })
  })
}

const FormPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/CreateForm') , "createForm"))
);

const DetailFormPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/CreateDetailForm'), "createDetailForm"))
);

const MainDashboard = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Dashboards/MainDashboard') , "mainDashboard"))
);

const UpdateFormPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/UpdateForm') , "updateForm"))
);

const GridPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/GridPage') , "gridPage"))
);

const EmbedPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/EmbedPage'), "embedPage"))
)

const User = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/User/Profile') , "profile"))
);

const ChangePassword = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/User/ChangePassword') , "changePassword"))
);

const ReportPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/ReportPage') , "reportPage"))
);

const ReportListPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Report') , "report"))
);

const ReportCreatePage = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Report/Create') , "create"))
);

const ReportUpdatePage = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Report/Update') , "update"))
);

const SurveyPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/dynamic/SurveyPage') , "surveyPage"))
);

const ThanksPage = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/Status/ThanksPage') , "thanksPage"))
);

const UserAssignments = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/User/Assignments') , "assignments"))
)

const UserApprovals = Loader(
  lazy(() => lazyRetry(() => import('src/content/pages/User/Approvals'), "approvals"))
)

const MainWorkflowGridPage = Loader(
  lazy(() => lazyRetry(() => import ('src/content/pages/Workflow/MainWorkflow'), "MainWorkflowGridPage"))
)


const router: RouteObject[] = [
  {
    path: '/:tenant/survey',
    element: (
      <TenantProvider>
        {/* <Guest>
          <BaseLayout />
        </Guest> */}
        <BaseLayout/>
      </TenantProvider>
    ),
    children: [
      {
        path: ':surveyCode', // updated path
        element: (
          <SurveyPage />
        )
      },
      {
        path: ':surveyCode/thanks',
        element: <ThanksPage />
      }
    ]
  },

  ...accountRoutes,
  ...baseRoutes,
  {
    path: '',
    element: (
      <Authenticated>
        <SidebarProvider>
          <TenantProvider>
            <AccentSidebarLayout />
          </TenantProvider>
        </SidebarProvider>
      </Authenticated>

    ),
    children: [
      {
        path: '/',
        element: <Navigate to='/dashboard' replace />
      }
      ,
      {
        path: '/dashboard',
        element: <MainDashboard />
      }
      ,
      {
        path: '/processes/:processCode/new',
        element: <FormPage />
      },
      {
        path: '/processes/:processCode/:id',
        element: <UpdateFormPage />
      },
      {
        path: '/processes/:masterProcessCode/:processCode/:id/new',
        element: <DetailFormPage />
      },
      {
        path: '/processes/:processCode',
        element: <GridPage />
      },
      {
        path: '/embed/:processCode',
        element: <EmbedPage />
      },
      {
        path: '/profile',
        element: <User />
      },
      {
        path: '/assignments',
        element: <UserAssignments />
      },
      {
        path: '/approvals',
        element: <UserApprovals/>
      },
      {
        path: '/workflow',
        element: <MainWorkflowGridPage />
      },
      {
        path: `/reports/:reportId/:reportType`,
        element: <ReportPage />

      },
      {
        path: `/reports`,
        element: <ReportListPage />
      },
      {
        path: `/reports/new`,
        element: <ReportCreatePage />
      },
      {
        path: `/reports/:reportId`,
        element: <ReportUpdatePage />
      },

      {
        path: '/change-password',
        element: <ChangePassword />
      }



    ]
  }
];

export default router;
