import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';


type LoaderProps = 
{
  loading: boolean
}

function Loader({ loading = true }: LoaderProps) {

  if (!loading) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999 // Ensure the loader appears on top of other elements
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* Overlay to prevent user interaction */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
          pointerEvents: 'none', // Allow pointer events to pass through
          zIndex: -1 // Place the overlay behind the loader
        }}
      />
      
      {/* Loader element */}
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
  
}

export default Loader;
