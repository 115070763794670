import ApiConstants from "src/constants/apiConstants";
import BaseHttpService from "../http/baseHttpService";
import { ProcessMenuItem } from "src/models/processMenuItem";
import { FormConfiguration } from "src/models/formConfiguration";
import { FormData as LocalFormData } from "src/models/formData";
import { AxiosResponse } from "axios";
import { ApiResult, Error } from "src/models/apiResult";
import { ProcessData } from "src/models/processData";
import FileHelper from "src/helpers/FileHelper";
import { ProcessAssignment } from "src/models/processAssignment";
import { RecordStatuses } from "src/models/recordStatuses";
import { workflowData } from "src/models/workflowData";
import { workflowHistoryData } from "src/models/workflowHistoryData";
import { EmbedViewConfiguration } from "src/models/embedViewConfiguration";
import { ApprovalData } from "src/models/approvalData";
import { ApproveStatuses } from "src/models/approveStatus";
import { staffData } from "src/models/staffData";
import { AssignmentData } from "src/models/assignmentData";
import { CompassApprovals } from "src/models/compassApproval";


class ProcessesHttpService {

    private _httpService: BaseHttpService;

    constructor() {
        this._httpService = new BaseHttpService(ApiConstants.ProcessesApi);
    }

    async getProcessesAsMenuItem(): Promise<ApiResult<ProcessMenuItem[]>> {
        return this.requestWrapper(this._httpService.get<ProcessMenuItem[]>("api/views/as-menu-item"));
    }

    async getProcessConfiguration(processCode: string): Promise<ApiResult<FormConfiguration>> {
        return this.requestWrapper(this._httpService.get<FormConfiguration>(`api/views/${processCode}/configuration`))
    }

    async getProcessEmbedConfiguration(processCode: string): Promise<ApiResult<EmbedViewConfiguration>> {
        return this.requestWrapper(this._httpService.get<EmbedViewConfiguration>(`/api/views/${processCode}/embed-configuration`))
    }

    async getRecords(processCode: string, startDate: Date, endDate: Date): Promise<ApiResult<LocalFormData[]>> {
        return this.requestWrapper(this._httpService.get<LocalFormData[]>(`/${processCode}/?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`));
    }

    async getRecord(processCode: string, recordId: number | string): Promise<ApiResult<ProcessData>> {
        return this.requestWrapper(this._httpService.get<ProcessData>(`/${processCode}/${recordId}`));
    }

    async getDetailRecords(processCode: string, startDate: Date, endDate: Date, masterRecordId: number | string): Promise<ApiResult<LocalFormData[]>> {
        return this.requestWrapper(this._httpService.get<LocalFormData[]>(`/${processCode}/detail/${masterRecordId}/?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`));
    }

    async getWorkflowHistories(startDate: Date, endDate: Date): Promise<ApiResult<workflowData[]>>{
        return this.requestWrapper(this._httpService.get<workflowData[]>(`api/workflows/?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`))
    }

    async getRecordWorkflowHistory(recordId: number): Promise<ApiResult<workflowHistoryData[]>>{
        return this.requestWrapper(this._httpService.get<workflowHistoryData[]>(`api/workflows/${recordId}`))
    }

    async getActiveApprovals(): Promise<ApiResult<ApprovalData[]>>{
        return this.requestWrapper(this._httpService.get<ApprovalData[]>('api/workflows/approvals'))
    }

    async getActiveAssignments(): Promise<ApiResult<AssignmentData[]>>{
        return this.requestWrapper(this._httpService.get<AssignmentData[]>('/api/workflows/assignments'))
    }

    async getRecordHistory(processCode: string, recordId: number): Promise<ApiResult<LocalFormData[]>> {
        return null;
    }

    async getRecordAsPdf(processCode: string, recordId: number): Promise<ApiResult<any>> {
        return this.requestWrapper(
            this._httpService.get(`/${processCode}/${recordId}/export/pdf`, { responseType: 'blob' })
        );
    }

    async getCompassUserApprovalCount(): Promise<ApiResult<CompassApprovals>> {
        return this.requestWrapper(this._httpService.get<CompassApprovals>('api/external/user_compass_approvals'))
    }

    async getRecordsAsPdf(processCode: string, rowIds: (number | string)[]): Promise<ApiResult<any>> {
        return this.requestWrapper(
            this._httpService.post(`/${processCode}/export-multiple/pdf`, rowIds, { responseType: 'blob' })
        );
    }

    async getStaffByFilter(staff_name_filter: string, staff_email_filter: string, staff_role_filter: string, staff_grade_filter): Promise<ApiResult<staffData[]>> {
        return this.requestWrapper(
            this._httpService.get<staffData[]>(`api/workflows/approvals/staff/?name=${staff_name_filter}&email=${staff_email_filter}&role=${staff_role_filter}&grade=${staff_grade_filter}`)            
        );
    }

    async reassignApproverStaff(workflowId: (string | number), staffId: (string | number)): Promise<ApiResult<any>>{
        const data = {
            workflowId: workflowId,
            staffId: staffId
        }
        
        return this.requestWrapper(
            this._httpService.put("/api/workflows/approvals/staff", data)
        )
    }


    async updateRecord(processCode: string, recordId: number | string , data: LocalFormData): Promise<ApiResult<LocalFormData>> {
        return this.requestWrapper(
            this._httpService.put(`/${processCode}/${recordId}`, data)
        );
    }

    async updateRecordStatus(processCode: string, recordId: number | string, recordStatus: number | string , comment: string, email: string): Promise<ApiResult<any>>{
        const statusData = {
            recordStatus: recordStatus,
            comment: comment,
            userEmail: email
        }
        
        return this.requestWrapper(
            this._httpService.put(`/${processCode}/${recordId}/status`, statusData)
        );
    }

    async setApprovalResult(workflowId: string | number, approvalResult: ApproveStatuses, comment: string, processName: string, recordId: string | number): Promise<ApiResult<any>> {
        const data = {
            workflowId: workflowId,
            approvalResult: approvalResult,
            comment: comment,
            processName: processName
        }

        return this.requestWrapper(
            this._httpService.put(`api/workflows/approvals/${recordId}`, data)
        )
    }

    async setAssignmentResult(workflowId: string | number, processName: string, recordId: string | number): Promise<ApiResult<any>> {
        const data = {
            workflowId: workflowId,
            processName: processName
        }

        return this.requestWrapper(
            this._httpService.put(`api/workflows/assignments/${recordId}`, data)
        )
    }

    async createRecord(processCode: string, data: LocalFormData, files?: File[], assignmentId?: number): Promise<ApiResult<LocalFormData>> {

        let base64Files: any[] = [];

        if (files) {
            base64Files =
                await Promise.all(files.map(async (file) => {
                    return {
                        fileName: file.name,
                        Base64String: await FileHelper.convertToBase64String(file)
                    }
                }
                ));
        }

        const formData = {
            values: data,
            files: base64Files,
            assignmentId: assignmentId
        };

        return this.requestWrapper(
            this._httpService.post(`/${processCode}`, formData)
        );
    }

    async createDetailRecord(processCode: string, data: LocalFormData, masterRecordId: string | number, files?: File[], assignmentId?: number): Promise<ApiResult<LocalFormData>> {

        let base64Files: any[] = [];

        if (files) {
            base64Files = 
                await Promise.all(files.map(async (file) => {
                    return {
                        fileName: file.name,
                        Base64String: await FileHelper.convertToBase64String(file)
                    }
                }
            ));
        }

        const formData = {
            values: data,
            files: base64Files,
            assignmentId: assignmentId
        };

        return this.requestWrapper(
            this._httpService.post(`/${processCode}/detail/${masterRecordId}`, formData)
        );
    }

    async deleteRecord(processCode: string, recordId: number | string): Promise<ApiResult<LocalFormData>> {
        return this.requestWrapper(
            this._httpService.delete(`/${processCode}/${recordId}`)
        );
    }

    async deleteRecordFile(processCode: string, recordId: number | string, fileId: number): Promise<ApiResult<LocalFormData>> {
        return this.requestWrapper(
            this._httpService.delete(`/${processCode}/${recordId}/files/${fileId}`)
        );
    }

    async createRecordFiles(processCode: string, recordId: number | string, formFiles: File[]): Promise<ApiResult<LocalFormData>> {

        const data = {
            viewCode: processCode,
            recordId: recordId,
            files: await Promise.all(formFiles.map(async (file) => {
                return {
                    fileName: file.name,
                    base64String: await FileHelper.convertToBase64String(file)
                }
            }
            ))
        }
        return this.requestWrapper(
            this._httpService.post(`/${processCode}/${recordId}/files`, data)
        );
    }

    async importRecordsAsExcel(processCode: string, file: File): Promise<ApiResult<any>> {
        const formData = new FormData();
        formData.append('file', file);

        return this.requestWrapper(
            this._httpService.post(`/${processCode}/bulk`, formData, { responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data' } })
        );

    }

        async getImportRecordSchemeAsExcel(processCode: string): Promise<ApiResult<any>> {
        return this.requestWrapper(
            this._httpService.get(`/${processCode}/bulk`, { responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data' , "Content-Disposition" : "attachment"} })
        );
    }

    // async getUserAssignments(userId: number): Promise<ApiResult<ProcessAssignment[]>> {
    //     return this.requestWrapper(
    //         this._httpService.get<ProcessAssignment[]>(`/api/assignments/${userId}`)
    //     );
    // }

    async downloadFileFromAzure(processCode: string, fileName: string, folderName: string, recordId: number | string): Promise<ApiResult<any>>{
        return this.requestWrapper(
            this._httpService.get(`/${processCode}/${folderName}/${fileName}/${recordId}` , {responseType: 'blob'})
        );
    }


    private async requestWrapper<T>(request: Promise<AxiosResponse<T>>): Promise<ApiResult<T>> {
        try {
            const response = await request;
            return this.formatResponse(response);
        } catch (error) {
            return this.formatErrorResponse(error);
        }
    }


    private formatResponse<T>(response: AxiosResponse<T>): ApiResult<T> {

        return {
            data: response.data,
            statusCode: response.status,
            isSuccess: this.isSuccessful(response.status),
            isFailure: !this.isSuccessful(response.status),
        };
    }

    private async formatErrorResponse(error: any): Promise<ApiResult<any>> {
        if (error.response) {
            let err: Error = { code: 'Unknown', message: 'Bilinmeyen hata' }
            try {
                if (error.response.data instanceof Blob && error.response.data.type === "application/json"){
                    const responseData = await error.response.data?.text();
                    err = JSON.parse(responseData)
                }
                else{
                    err = (error.response.data);
                }
            } catch (errorCatch) {
                console.error(errorCatch);

                err = { code: 'Unknown', message: 'Bilinmeyen hata' }
            }


            return {
                data: error.response.data,
                statusCode: error.response.status,
                isSuccess: false,
                isFailure: true,
                error: err
            };
        } else {
            return {
                data: null,
                statusCode: 0,
                isSuccess: false,
                isFailure: true,
                error: { code: 'Unknown', message: 'Bilinmeyen hata' }
            };
        }
    }

    private isSuccessful(status: number): boolean {
        return status >= 200 && status < 300;
    }



}

export default new ProcessesHttpService;