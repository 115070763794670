import { Campaign, Circle, Close, ExpandMore, NewReleases } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
    `
);

interface Release {
  version: string;
  date: string;
  changes: string[];
}

function ChangeLogs() {
  const [changeNotes, setChangeNotes] = useState<Release[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [dotInvisible, setDotInvisible] = useState(true);

  useEffect(() => {
    fetch('/releases.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Couldn't reach to '/releases.json' file, please check"
          );
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          const latestChange = data[data.length - 1];
          const lastSeenVersion = localStorage.getItem('lastSeenVersion');
          data = data.slice(0, 15);
          setChangeNotes(data.reverse());

          if (!lastSeenVersion || lastSeenVersion !== latestChange.version) {
            setDotInvisible(false);
          }
        }
      })
      .catch((error) => {
        console.error(
          'There was a problem with fetch operation in change logs:',
          error
        );
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setDotInvisible(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (changeNotes[0]) localStorage.setItem('lastSeenVersion', changeNotes[0].version);
  };

  return (
    <>
      <Tooltip arrow title={'GocturMain Son Değişiklikler'}>
        <IconButton color={dotInvisible ? "secondary" : "primary"} onClick={handleOpen}>
          <Badge color="primary" variant="dot" invisible={dotInvisible}>
            <Campaign />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 360,
            maxHeight: 520
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <SectionHeading
              variant="body2"
              color="text.primary"
              sx={{ marginBottom: 1 }}
            >
              Son Değişiklikler
            </SectionHeading>
            <IconButton color="error" onClick={handleClose}>
              <Close/>
            </IconButton>
          </Box>
          {changeNotes.map((change, index) => (
            <>
              <Divider />
              <Accordion key={index} defaultExpanded={changeNotes[0] && index === 0 ? true : false}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    sx={{
                      width: '33%',
                      flexShrink: 0,
                      fontStyle: 'italic',
                      color: 'text.secondary'
                    }}
                  >
                    {dayjs(change.date, 'DD-MM-YYYY').format('DD.MM.YYYY')}
                  </Typography>
                  <Typography
                    sx={{ color: 'text.primary', fontWeight: 'bold' }}
                  >
                    {change.version}
                  </Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                  <List dense={true}>
                    {change.changes.map((localChange, localIndex) => (
                      <ListItem key={localIndex}>
                        <ListItemIcon sx={{minWidth: '10%'}}>
                          <Circle sx={{fontSize: '10px'}}/>
                        </ListItemIcon>
                        <ListItemText
                          primary={localChange}
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Divider />
            </>
          ))}
        </Box>
      </Popover>
    </>
  );
}

export default ChangeLogs;
