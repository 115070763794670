
class FileHelper {

    /**
     * Returns the file as a base64 string
     * @param file 
     * @returns 
     */
    async convertToBase64String(file: File): Promise<string> {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return await new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result.toString());
                reader.onerror = error => reject(error);
            });
        } catch (error) {
            console.error(error);
            return null;
        }
    }


}

export default new FileHelper();