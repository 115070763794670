import React, { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import SidebarTopSection from './SidebarTopSection';

import { Box, Drawer, styled, useMediaQuery, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import useSidebar from 'src/hooks/useSidebar';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        transition: '2.2s cubic-bezier(.36,-0.01,0,.77)',
        pt : ${theme.header.height};
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const sidebarOpen = useSidebar(state => state.sidebarToggle);
  const toggleSidebarFnc = useSidebar(state => state.toggleSidebar);

  let isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));




  const closeSidebar = () => toggleSidebarFnc();
  const theme = useTheme();

  return (
    <>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarOpen}
        onClose={closeSidebar}
        variant={isMobile ? 'temporary' : 'persistent'}
        elevation={9}
        ModalProps={
          {
            keepMounted: true // Better open performance on mobile.
          } as any
        }
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 4,
                  mt: 1,
                  mb: 3
                }}
              >
                <Logo />
              </Box>
              {/* <SidebarTopSection /> */}
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default React.memo(Sidebar);
