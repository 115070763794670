import { ActionTypes, AuthResource } from "src/models/authResource";
import { Permission } from "src/models/permission";



class AuthHelper {


    isAuthorized(authClaims: AuthResource[], resourceCode: string, actionType: ActionTypes = null): boolean {

        if (authClaims == null || authClaims.length == 0) {
            return false;
        }

        if (actionType == null) {
            return authClaims.some(x => x.resourceCode == resourceCode);
        }

        return authClaims.some(x => x.resourceCode == resourceCode && x.actionTypes.some(y => y === actionType));
    }

    getPermission(authClaims : AuthResource[], resourceCode : string) : Permission {
        let authResource = authClaims.find(x => x.resourceCode == resourceCode);
        if(authResource == null){
            return {
                canDelete : false,
                canInsert : false,
                canUpdate : false,
                canView : false,
                canExport : false,
                canImport : false,
                canGetRecord : false,
                canList : false,
                canListOwner : false,
                canDepartmentOwner : false,
            } as Permission;
        }

        return {
            canDelete : this.isAuthorized(authClaims, resourceCode, ActionTypes.Delete),
            canInsert : this.isAuthorized(authClaims, resourceCode, ActionTypes.Insert),
            canUpdate : this.isAuthorized(authClaims, resourceCode, ActionTypes.Update),
            canView : this.isAuthorized(authClaims, resourceCode, ActionTypes.CanView),
            canExport : this.isAuthorized(authClaims, resourceCode, ActionTypes.Export),
            canImport : this.isAuthorized(authClaims, resourceCode, ActionTypes.Import),
            canGetRecord : this.isAuthorized(authClaims, resourceCode, ActionTypes.GetRecord),
            canList : this.isAuthorized(authClaims, resourceCode, ActionTypes.List),
            canListOwner : this.isAuthorized(authClaims, resourceCode, ActionTypes.ListOwner),
            canDepartmentOwner : this.isAuthorized(authClaims, resourceCode, ActionTypes.DepartmentOwner),
        } as Permission; 

    }
}

export default new AuthHelper();