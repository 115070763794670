export interface AuthResource
{
    parentResourceCode? : string;
    resourceCode : string;
    resourceName : string;
    actionTypes : ActionTypes[]
}

export enum ActionTypes {
    CanView = 1,
    List = 2,
    GetRecord = 3,
    Insert = 4,
    Update = 5,
    Delete = 6,
    Import = 7,
    Export = 8,
    ListOwner = 10,
    DepartmentOwner = 14
}