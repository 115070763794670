import { useContext } from 'react';

import { Box, alpha, IconButton, Tooltip, styled, Badge, Chip, useMediaQuery } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Logo from 'src/components/LogoSign';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderSearch from './Search';
import useSidebar from 'src/hooks/useSidebar';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
       height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        transition: 0.5s cubic-bezier(.36,-0.01,0,.77);
       
`
);

function Header() {
  const sidebarToggle = useSidebar(state => state.sidebarToggle);
  const toggleSidebar = useSidebar(state => state.toggleSidebar);

  // is mobile


  return (
    <HeaderWrapper sx={theme => ({
      left: sidebarToggle ? theme.sidebar.width : undefined,
      width: sidebarToggle ? 'auto' : '100%',
    })} display="flex" alignItems="center">

      <Box display="flex" alignItems="center">
        <Box
          component="span"
        >
          <Tooltip arrow title="Menüyü açıp kapatmak için tıklayın.">
            <IconButton color="primary" onClick={toggleSidebar}>
              <MenuTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Box>
          <HeaderSearch />
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />

      </Box>
    </HeaderWrapper>
  );
}

export default Header;
