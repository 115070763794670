const ruJSON = {
    'previous': 'Предыдущий',
    'next': 'Следующий',
    'submit' : 'Отправить',
    'copyright' : 'Все права сохранены',
    'file_upload_button_tooltip' : 'Загрузить файл',
    'uploadedFiles' : 'Загруженные файлы',
    'plsFillFieldsAlert' : 'Пожалуйста, заполните все поля, отмеченные *',
    'yes' : 'Да',
    'no' : 'Нет',
    'other' : 'Другое',
    'general' : 'Общее',
    'accept' : 'Принять',
    'cancel' : 'Отменить',
    'kvkktitle' : 'Политика защиты персональных данных',
    'changelanguage' : 'Изменить язык',
    'thanksTitle' : 'Спасибо!',
    'thanksSubTitle' : 'Благодарим вас за обратную связь.',
    'submitAgain' : 'Отправить анкету снова',
};
export default ruJSON;
