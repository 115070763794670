import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Tenant } from 'src/models/tenant';
import tenantService from 'src/services/http/tenantService';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import AuthService from 'src/services/auth/authService';
import Loader from 'src/components/Loader';
import { ThemeContext } from 'src/theme/ThemeProvider';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg'
};

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const [tenants, setTenants] = React.useState<Tenant[]>([]);

  const [email, setEmail] = React.useState<string>('');

  const [loading , setLoading] = React.useState<boolean>(false);

  const setThemeName = useContext(ThemeContext);


  const { enqueueSnackbar } = useSnackbar();


  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    setThemeName('GocturTheme');
  },[])


  const handleNext = (e) => {
    e.preventDefault();
    setLoading(true);
    tenantService
      .getUserAllowedTenants(email)
      .then((response) => {

        setLoading(false);

        if (response.length === 0) {
          enqueueSnackbar("Bu email adresi ile bağlantılı bir işletme bulunamadı.", { variant: 'error' })
          return;
        }
        else if (response.length === 1) {
          AuthService.login(email, response[0].tenantId);
          return;
        }

        setTenants(response);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch(err => {
        setLoading(false);
        let errorMessage = err?.response?.data?.message ?? (err?.message ?? "İşletmeler çekilirken bir hata oluştu.");
        enqueueSnackbar(errorMessage, { variant: 'error' })
      });
  }

  const handleTenantSelection = (tenant: number) => {
    setLoading(true);
    AuthService.login(email, tenant);
    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>GöçturMain | Giriş</title>
      </Helmet>
      <Loader loading={loading} />
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Logo />
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Giriş Yapın')}
                </Typography>
                <Typography variant='h6'>
                  Yenilenen GöçturMain'e hoşgeldiniz.
                </Typography>
              </Box>
              <Box component="form" onSubmit={handleNext} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                >
                  İleri
                </Button>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'right',
                    mt: 2
                  }}
                >
                  <RouterLink to="/recover-password">
                    Şifrenizi mi unuttunuz ?
                  </RouterLink>
                </Typography>
              </Box>

              <Dialog open={activeStep === 1}>
                <DialogTitle>
                  İşletme Seçiniz.
                  <IconButton
                    aria-label="close"
                    onClick={() => setActiveStep(0)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle >
                <List sx={{ pt: 0 }}>
                  {tenants.map((tenant) => (
                    <ListItem key={tenant.tenantId} disableGutters>
                      <ListItemButton onClick={() => handleTenantSelection(tenant.tenantId)} key={tenant.tenantId}>
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${tenant.tenantName} ${tenant.friendlyName === null ? "" : `| ${tenant.friendlyName}`}`} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

              </Dialog>
            </Card>

          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
