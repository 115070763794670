import {
  Fragment,
  forwardRef,
  Ref,
  useState,
  ReactElement,
  ChangeEvent,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react';
import {
  Box,
  Divider,
  IconButton,
  CircularProgress,
  InputBase,
  Tooltip,
  Typography,
  Card,
  Dialog,
  alpha,
  Slide,
  styled,
  useTheme,
  debounce,
  Badge,
  Chip
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { MenuItem, flattenMenuItems, searchMenuItems } from '../../Sidebar/SidebarMenu/items';
import useSidebar from 'src/hooks/useSidebar';
import cacheService from 'src/services/storage/cacheService';
import { useNavigate } from 'react-router-dom';

const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
);


function HeaderSearch() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();


  const menuItems = useSidebar(state => state.menuItems);

  const favourites = useSidebar(state => state.favourites);

  const addFavourite = useSidebar(state => state.addFavourite);

  const removeFavourite = useSidebar(state => state.removeFavourite);


  const [searchState, setSearchState] = useState({
    value: '',
    loading: false,
    results: [],
  });

  const [recentPages, setRecentPages] = useState(cacheService.get<string[]>('recentPages') || [])

  const searchInputRef = useRef(null);
  const flattenedMenuItems = useMemo(
    () => flattenMenuItems(menuItems),
    [menuItems]
  );

  const navigator = useNavigate();


  const handleSearch = async (value) => {
    setSearchState({ value, loading: true, results: [] });

    await wait(500);
    let result = searchMenuItems(menuItems, value);

    setSearchState({ value, loading: false, results: result });
  }

  const submitSearch = (event): void => {
    event.preventDefault();
    handleSearch(searchState.value);
  };

  const handleSearchChange = (event: ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    const value = event.target.value as string;

    if (value) {
      handleSearch(value);
    } else {
      setSearchState({ value: '', loading: false, results: [] });
    }
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 250);


  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault();
        setOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!open) return;
    setRecentPages(cacheService.get<string[]>('recentPages') || []);
    searchInputRef.current.focus();
  }, [open]);


  const removeRecent = (link: string) => {
    const newRecentPages = [...recentPages];
    const index = newRecentPages.findIndex(f => f === link);

    if (index === -1) return;
    newRecentPages.splice(index, 1);
    setRecentPages(newRecentPages);
    cacheService.set('recentPages', newRecentPages);
  }



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = useCallback((link: string) => {

    handleClose();
    navigator(link);
  }, []);

  return (
    <>
      <Tooltip arrow title="Ara (Ctrl+K)">
        <IconButtonWrapper color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <Box>
          <form onSubmit={submitSearch}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center">
                <SearchTwoToneIcon
                  sx={{
                    ml: 2,
                    color: theme.colors.secondary.main
                  }}
                />
                <SearchInputWrapper
                  onChange={debouncedHandleSearchChange}
                  autoFocus
                  onFocus={event => {
                    event.target.select();
                  }}
                  inputRef={searchInputRef}
                  placeholder={t('Aradığınız süreci yazın...')}
                  fullWidth
                />
              </Box>

              <IconButton
                sx={{
                  ml: 'auto',
                  mr: 2,
                  py: 0.5,
                  px: 1,
                  background: theme.colors.alpha.black[10]
                }}
                color='secondary'
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </form>
        </Box>
        <Divider />
        {!searchState.loading && (searchState.value === '') ?
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              background: theme.colors.info.lighter,
              color: theme.colors.info.main,
              borderRadius: theme.general.borderRadius,
              fontSize: theme.typography.pxToRem(13),
              display: 'flex',
              alignItems: 'center',
              p: 1,
              mx: 2,
              my: 2
            }}
          >
            <ContactSupportTwoToneIcon
              sx={{
                mr: 0.8,
                fontSize: theme.typography.pxToRem(18)
              }}
            />
            Aramak için bir şeyler yazın.
          </Typography>
          : null
        }

        {
          searchState.loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 5
              }}
            >
              <CircularProgress />
            </Box>
          )
        }

        {/*Search Results  */}
        {
          <>
            {searchState.results.length > 0 && !searchState.loading && searchState.value
              ? (
                <Box
                  sx={{
                    height: 450
                  }}
                >
                  <Scrollbar>
                    {searchState.results.map((menuItem) => (
                      <Box pb={5} px={2} py={1} key={1}>
                        <Typography
                          sx={{
                            py: 1
                          }}
                          variant="h5"
                        >
                          {menuItem.heading}
                        </Typography>
                        {menuItem.items.map((page) => (
                          <Fragment key={page.link}>
                            <ListButton onClick={() => handleNavigate(page.link)}>
                              <Box display="flex" alignItems="flex-start">
                                <RestoreTwoToneIcon
                                  sx={{
                                    mr: 1
                                  }}
                                  fontSize="small"
                                />
                                <Typography>{page.name}</Typography>
                              </Box>
                              <Box display={'flex'} alignItems={'center'}>
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title={t(favourites.some(f => f.link === page.link) ? 'Favorilerden kaldır' : 'Favorilere ekle')}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addFavourite(page);
                                    }}
                                    disabled={favourites.some(f => f.link === page.link)}
                                    size="small" color="primary">
                                    <StarTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <KeyboardArrowRightTwoToneIcon fontSize="small" />

                              </Box>
                            </ListButton>
                          </Fragment>
                        ))}
                      </Box>
                    ))}
                  </Scrollbar>
                </Box>
              )
              : null
            }
          </>
        }

        {/* Recents and favourites. */}

        {
          !searchState.loading && searchState.value === '' ?
            <Box
              sx={{
                height: 450
              }}
            >
              <Scrollbar>
                <Box pb={2} px={2}>
                  <Typography
                    sx={{
                      pb: 0.5
                    }}
                    variant="h5"
                  >
                    {t('Son ziyaret edilenler')}
                  </Typography>
                  {
                    recentPages
                      .map(page => flattenedMenuItems.find(mI => mI.link === page))
                      .filter(mI => mI)
                      .map((page, index) => (
                        <ListButton onClick={() => handleNavigate(page.link)} key={page.link + '_recents'}>
                          <Box display="flex" alignItems="center">
                            <RestoreTwoToneIcon
                              sx={{
                                mr: 1
                              }}
                              fontSize="small"
                            />
                            <Typography>{page.name}</Typography>
                          </Box>
                          <Box>
                            <Tooltip
                              placement="top"
                              arrow
                              title={t(favourites.some(f => f.link === page.link) ? 'Favorilerden kaldır' : 'Favorilere ekle')}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addFavourite(page);
                                }}
                                disabled={favourites.some(f => f.link === page.link)}
                                size="small" color="primary">
                                <StarTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              arrow
                              title={t('Geçmişten kaldır')}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeRecent(page.link);
                                }}

                                size="small" color="error">
                                <CloseTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </ListButton>

                      ))

                  }



                  <Typography
                    sx={{
                      pt: 2,
                      pb: 0.5
                    }}
                    variant="h5"
                  >
                    {t('Favoriler')}
                  </Typography>
                  {
                    favourites.map((page, index) => (
                      <ListButton onClick={() => handleNavigate(page.link)} key={page.link + '_favourite'}>
                        <Box display="flex" alignItems="center">
                          <StarTwoToneIcon
                            sx={{
                              mr: 1
                            }}
                            fontSize="small"
                          />
                          <Typography>{page.name}</Typography>
                        </Box>
                        <Box>
                          <Tooltip
                            placement="top"
                            arrow
                            title={t('Favorilerden kaldır')}
                          >
                            <IconButton onClick={(e) => {
                              e.stopPropagation();
                              removeFavourite(page.link)
                            }} size="small" color="error">
                              <CloseTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </ListButton>

                    ))
                  }
                </Box>
              </Scrollbar>
            </Box>
            : null
        }


      </DialogWrapper >
    </>
  );
}

export default HeaderSearch;
