import { matchPath } from 'react-router-dom';
import { MenuItem } from 'src/layouts/AccentSidebarLayout/Sidebar/SidebarMenu/items';

class SidebarHelper {
    hasPartialMatch(items: MenuItem[], path: string): boolean {
        return items.some(child =>
            child.items?.length
                ? this.hasPartialMatch(child.items, path)
                : child?.link && matchPath({path: child.link, end: false}, path)
        );
    }
}

export default new SidebarHelper();
