import ApiConstants from "src/constants/apiConstants";
import BaseHttpService from "../http/baseHttpService";
import { AuthResourceMenuItem } from "src/models/authResourceMenuItem";
import { ResponseWrapper } from "src/models/responseWrapper";

class AdminService {


    private _httpService: BaseHttpService;

    constructor() {
        this._httpService = new BaseHttpService(ApiConstants.AdminApi);
    }

    async getForgotPasswordEmail(forgotPasswordKey: string): Promise<ResponseWrapper<string>> {

        try{
            let response = await this._httpService.post<ResponseWrapper<string>>('/auth/getForgotPasswordEmail', {
                forgotPasswordKey
            });
    
        
            return response.data;
        }
        catch(error){
            return {
                success: false,
                message: error.message,
                data: null
            }
        }

    }

    async resetPassword (email: string, newPassword: string, forgotPasswordKey: string): Promise<ResponseWrapper<any>> {

        try{
            let response = await this._httpService.post<ResponseWrapper<any>>('/auth/resetPassword', {
                email,
                password:newPassword,
                forgotPasswordKey
            });

            return response.data;
        }
        catch(error){
            return {
                success: false,
                message: error.message,
                data: null
            }
        }
    }

    async getResourcesAsMenuItems(): Promise<AuthResourceMenuItem[]> {

        let response = await this._httpService.get<ResponseWrapper<AuthResourceMenuItem[]>>('/authResources/listAsMenuItem');

        if (!response.data.success) {
            throw new Error(response.data.message);
        }

        return response.data.data;

    }

    async changePassword(email: string, oldPassword: string, newPassword: string): Promise<ResponseWrapper<any>> {

        try {
            let response = await this._httpService.post<ResponseWrapper<any>>('/auth/changePassword', {
                email: email,
                currentPassword: oldPassword,
                newPassword: newPassword
            });

            return response.data;
        } catch (error) {
            return {
                success: false,
                message: error.response?.data?.message
                    ? error.response?.data?.message
                    : error.message,
                data: null
            };
        }

    }

    async forgotPassword(email: string): Promise<ResponseWrapper<any>> {

        try {
            let response = await this._httpService.post<ResponseWrapper<any>>('/auth/forgotPassword', {
                email: email
            });

            return response.data;
        } catch (error) {
            return {
                success: false,
                message: error.message,
                data: null
            }
        }

    }

}

export default new AdminService;