const plJSON = {
    'previous': 'Poprzedni',
    'next': 'Następny',
    'submit' : 'Wyślij',
    'copyright' : 'Wszelkie prawa zastrzeżone.',
    'file_upload_button_tooltip' : 'Prześlij plik',
    'uploadedFiles' : 'Przesłane pliki',
    'plsFillFieldsAlert' : 'Proszę wypełnić wszystkie pola oznaczone *',
    'yes' : 'Tak',
    'no' : 'Nie',
    'other' : 'Inne',
    'general' : 'Ogólne',
    'accept' : 'Akceptuj',
    'cancel' : 'Anuluj',
    'kvkktitle' : 'Polityka ochrony danych osobowych',
    'changelanguage' : 'Zmień język',
    'thanksTitle' : 'Dziękujemy!',
    'thanksSubTitle' : 'Dziękujemy za opinię.',
    'submitAgain' : 'Wyślij ankietę ponownie',
};

export default plJSON;
