import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from '../auth/authService'
import i18n from 'src/i18n/i18n';

class BaseHttpService {
    private axiosInstance: AxiosInstance;

    constructor(base_url: string) {
        this.axiosInstance = axios.create({
            baseURL: base_url,
        });

        this.axiosInstance.interceptors.request.use(async config => {
            const token = await AuthService.getAccessToken();

            const language = i18n.language;
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            config.headers['Accept-Language'] = language || 'tr';

            return config;
        });

        this.axiosInstance.interceptors.response.use(this.handleResponse, this.handleError);
    }

    private handleResponse(response: AxiosResponse) {
        return response;
    }

    private handleError(error: AxiosError) {
        // Here you can handle the error according to the error response status
        // For example, you can redirect to login when the token is expired (status 401)
        if (error.response?.status === 401) {
            AuthService.logout();
        }
        // You can also add a general error message here or do other error handling
        return Promise.reject(error);
    }

    async get<T = any, R = AxiosResponse<T>>(url: string, config: AxiosRequestConfig = null): Promise<R> {
        return this.axiosInstance.get<T, R>(url, config)
    }

    async post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config: AxiosRequestConfig = null): Promise<R> {
        return this.axiosInstance.post<T, R>(url, data, config);
    }

    async put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config: AxiosRequestConfig = null): Promise<R> {
        return this.axiosInstance.put<T, R>(url, data, config);
    }

    async patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config: AxiosRequestConfig = null): Promise<R> {
        return this.axiosInstance.patch<T, R>(url, data, config);
    }

    async delete<T = any, R = AxiosResponse<T>>(url: string, config: AxiosRequestConfig = null): Promise<R> {
        return this.axiosInstance.delete<T, R>(url, config);
    }
}

export default BaseHttpService;