import { FC, useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { ThemeContext } from 'src/theme/ThemeProvider';
import { createContext } from 'use-context-selector';

export interface TenantContextType {
    tenant: Tenant
};

interface Tenant {
    tenantId: number;
    tenantName?: string;
    personalInformationDisclosureAgreement?: string;
    backgroundImagePath?: string;
    isTenantSet: boolean;
    themeName: string;
}

export const TenantContext = createContext<TenantContextType>(
    {} as TenantContextType
);

export type TenantMappingKey = 'pinebay' | 'goctur' |  'dtbhkusadasi';
export type TenantMappingId = 4 | 3 | 5;

export const defaultTenant: { id: TenantMappingId; key: TenantMappingKey } = {
    id: 3,
    key: 'goctur',
};

export const tenantMapping: Record<TenantMappingKey, TenantMappingId> = {
    'pinebay': 4,
    'goctur': 3,
    'dtbhkusadasi': 5,
}

export const tenantInfoMapping: Record<number, { name, personalInformationDisclosureAgreement, backgroundImagePath, themeName }> = {
    4: {
        name: 'Pine Bay Hotels & Resorts',
        personalInformationDisclosureAgreement: 'kuspbKVKK',
        backgroundImagePath: '/static/images/backgrounds/pinebay.jpg',
        themeName: 'PinebayTheme'
    },
    3: {
        name: 'Göçtur Turizm Yatırım ve Ticaret A.Ş.',
        personalInformationDisclosureAgreement: '',
        backgroundImagePath: '/static/images/backgrounds/goctur.png',
        themeName: 'PureLightTheme'

    },
    5: {
        name: 'Double Tree By Hilton Kuşadası',
        personalInformationDisclosureAgreement: 'xkukuKVKK',
        backgroundImagePath: '/static/images/backgrounds/hilton.jfif',
        themeName: 'HiltonTheme'
    },
}

export const TenantProvider: FC = ({ children }) => {

    const [tenant, setTenant] = useState<Tenant>(
        {
            tenantId: 3,
            tenantName: 'Göçtur Turizm Yatırım ve Ticaret A.Ş.',
            personalInformationDisclosureAgreement: '',
            backgroundImagePath: '/static/images/backgrounds/goctur.png',
            isTenantSet: false,
            themeName: 'GocturTheme'
        }
    );

    const isAuthenticated = useAuth(selector => selector.isAuthenticated);
    const isInitialized = useAuth(selector => selector.isInitialized)

    const DEFAULT_USER_TENANT_VALUE = 0;

    const user = useAuth(selector => selector.user);
    const logout = useAuth(selector => selector.logout);
    const location = useLocation();
    const setThemeName = useContext(ThemeContext);


    useEffect(() => {
        if (!isInitialized) return;

        if (!isAuthenticated) {
            let tenant = location.pathname.split('/')[1]

            let tenantId = tenantMapping[tenant] || defaultTenant.id;

            setTenant({
                tenantId: tenantId,
                tenantName: tenantInfoMapping[tenantId]?.name,
                personalInformationDisclosureAgreement: (tenantInfoMapping[tenantId]?.personalInformationDisclosureAgreement),
                backgroundImagePath: tenantInfoMapping[tenantId]?.backgroundImagePath,
                isTenantSet: true,
                themeName: tenantInfoMapping[tenantId]?.themeName,
            })
        }
        else {
            if (user?.tenantId == DEFAULT_USER_TENANT_VALUE){
                logout();
            }

            setTenant({ 
                tenantId: user?.tenantId, 
                tenantName: tenantInfoMapping[user?.tenantId]?.name, 
                personalInformationDisclosureAgreement: tenantInfoMapping[user?.tenantId]?.personalInformationDisclosureAgreement, 
                backgroundImagePath: tenantInfoMapping[user?.tenantId]?.backgroundImagePath,
                isTenantSet: true,
                themeName: tenantInfoMapping[user?.tenantId]?.themeName,
            })
        }
    }, [user, location, isInitialized]);

    useEffect(() => {
        setThemeName(tenant?.themeName);
    },[tenant])

    const contextValue = useMemo(() => ({ tenant }), [tenant]);



    return (
        <TenantContext.Provider
            value={contextValue}
        >
            {children}
        </TenantContext.Provider>
    );
};