import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login';
import AuthHelper from 'src/helpers/AuthHelper';
import { ActionTypes } from 'src/models/authResource';
import AppInit from '../AppInit';

interface AuthenticatedProps {
  children: ReactNode;
  resourceCode? : string;
}

const Authenticated: FC<AuthenticatedProps> = (props: AuthenticatedProps) => {
  const { children , resourceCode } = props;
  const isAuthenticated = useAuth(selector=> selector.isAuthenticated);

  console.log('Authenticated - Component');
  

  const authClaims = useAuth(selector=> selector.authClaims);

  const isInitialized = useAuth(selector=> selector.isInitialized);
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!isInitialized) {
    return <AppInit />;
  }


  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (resourceCode && !AuthHelper.isAuthorized(authClaims, resourceCode,ActionTypes.CanView)) {
    return <Navigate to="/403" />;
  }
  

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
  resourceCode: PropTypes.string
};

export default Authenticated;
