import { useLocation, useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { useEffect, useState } from 'react';
import cacheService from './services/storage/cacheService';
import { TenantProvider } from './contexts/TenantContext';
import i18n from './i18n/i18n';
import { AuthProvider } from './contexts/OidcAuthContext';

function App() {
  const content = useRoutes(router);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const location = useLocation();

  useEffect(() => {
    // get recent pages from local storage
    let recentPages = cacheService.get<string[]>('recentPages') || [];

    // if the current page is not in recent pages, add it. If it exists remove it and add it again to the top
    const index = recentPages.findIndex((url) => url === location.pathname);

    if (index === -1) {
      recentPages = [location.pathname, ...recentPages];
    }
    else {
      recentPages.splice(index, 1);

      recentPages = [location.pathname, ...recentPages];
    }

    // keep only the first 5 pages
    recentPages = recentPages.slice(0, 5);

    // save recent pages to local storage
    cacheService.set('recentPages', recentPages);


  }, [location]);

  i18n.on('languageChanged', setSelectedLanguage);

  return (
    <ThemeProvider>
      <LocalizationProvider locale={selectedLanguage} dateAdapter={AdapterDayjs}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          <AuthProvider>
            {content}
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
