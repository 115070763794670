type CachePopulator<T> = () => T;

type CachePopulatorAsync<T> = () => Promise<T>;

interface CacheValue<T> {
    data: T;
    expiration: number;
}

class CacheService<T> {
    remove(key: string) {
        localStorage.removeItem(key);
    }
    
    /**
     * 
     * @param key key for local storage
     * @param populator populator function
     * @param ttl time to live in milliseconds. Default is 30 days
     * @returns cached value or populator result
     */
    getOrCreate(key: string, populator: CachePopulator<T>, ttl: number = 2_629_056_000): T {
        const now = Date.now();
        let cachedValue: CacheValue<T> | null = null;

        try {
            cachedValue = this.getFromLocalStorage<T>(key);
        } catch (error) {
            console.error(`Error getting data from LocalStorage for key "${key}":`, error);
        }

        if (cachedValue && cachedValue.expiration > now) {
            return cachedValue.data;
        }

        const data = populator();

        try {
            this.setToLocalStorage(key, { data, expiration: now + ttl });
        } catch (error) {
            console.error(`Error setting data to LocalStorage for key "${key}":`, error);
        }

        return data;
    }

    async getOrCreateAsync(key: string, populator: CachePopulatorAsync<T>, ttl: number = 2_629_056_000): Promise<T> {

        const now = Date.now();
        let cachedValue: CacheValue<T> | null = null;

        try {
            cachedValue = this.getFromLocalStorage<T>(key);
        } catch (error) {
            console.error(`Error getting data from LocalStorage for key "${key}":`, error);
        }

        if (cachedValue && cachedValue.expiration > now) {
            return Promise.resolve(cachedValue.data);
        }

        const data = await populator();

        try {
            this.setToLocalStorage(key, { data, expiration: now + ttl });
        } catch (error) {
            console.error(`Error setting data to LocalStorage for key "${key}":`, error);
        }

        return data;

    }

    /**
     * 
     * @param key key for local storage
     * @param value cached value
     * @param ttl time to live in milliseconds. Default is 30 days
     */
    set(key: string, value: any, ttl: number = 2_629_056_000) {
        const now = Date.now();

        try {
            this.setToLocalStorage(key, { data: value, expiration: now + ttl });
        }
        catch (error) {
            console.error(`Error setting data to LocalStorage for key "${key}":`, error);
        }
    }

    /**
     * 
     * @param key key for local storage
     * @returns cachedValue or null
     */
    get<T>(key: string): T | null {

        const cachedValue = this.getFromLocalStorage<T>(key);
        const now = Date.now();

        if (!cachedValue || cachedValue?.expiration < now)
            return null;

        if (cachedValue && cachedValue.expiration > now)
            return cachedValue.data;
    }

    private getFromLocalStorage<T>(key: string): CacheValue<T> | null {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    private setToLocalStorage(key: string, value: CacheValue<T>): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

export default new CacheService;
